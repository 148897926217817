import Sortable from 'sortablejs';
import axios from 'axios';

export const initSortable = (
  listId,
  item,
  field,
  handler,
  callback,
  onstart,
) => {
  const container = document.getElementById(listId);
  const setOrder = () => {
    container.querySelectorAll(item).forEach((el, ind) => {
      if (field !== undefined) {
        const fieldEl = el.querySelector(field);
        if (fieldEl) { fieldEl.value = ind + 1; }
      }
    });
  };

  const handleStart = () => {
    if (onstart && typeof (onstart) === 'function') {
      onstart();
    }
  };

  const handleFinish = (evt) => {
    setOrder();
    if (callback && typeof (callback) === 'function') {
      callback(evt);
    }
  };

  const sortable = new Sortable(container, {
    draggable: item,
    handle: handler,
    onStart: handleStart,
    onEnd: handleFinish,
    dataIdAttr: 'data-order',
    animation: 150,
    easing: 'cubic-bezier(1, 0, 0, 1)',
    ghostClass: 'gr-sortable_item_placeholder',
  });

  setOrder();
  return sortable;
};

window.initSortable = initSortable;

export const updateSortOrder = (evt) => {
  if (evt.item.dataset.orderUrl) {
    axios.put(evt.item.dataset.orderUrl, {
      position: evt.newDraggableIndex,
    });
  }
};
window.updateSortOrder = updateSortOrder;
