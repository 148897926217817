import { Controller } from "@hotwired/stimulus";
import { removeQuerystring } from "../../src/custom/UrlParams";

export default class extends Controller {
  changeAttendeeType(event) {
    const attendeeTypeId = event.currentTarget.value;

    const newFieldMappingButton = document.querySelector("#new_field_mapping");
    const fieldMappingBaseUrl = removeQuerystring(newFieldMappingButton.href);
    newFieldMappingButton.href = `${fieldMappingBaseUrl}?concur_attendee_type_id=${attendeeTypeId}`;

    const defaultMappingsButton = document.querySelector(
      "#default_mappings_button",
    );
    const baseUrl = removeQuerystring(defaultMappingsButton.href);
    defaultMappingsButton.href = `${baseUrl}?concur_attendee_type_id=${attendeeTypeId}`;

    document.querySelectorAll(".field-mapping-table").forEach((table) => {
      if (table.dataset.attendeeTypeId === attendeeTypeId) {
        table.classList.remove("tw-hidden");
      } else {
        table.classList.add("tw-hidden");
      }
    });
  }
}
