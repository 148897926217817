import { showModal } from '../Modal';
import { titleize } from '../Utils';
import { i18n } from '../Internationalization';
import { getCSRFTokenValue } from '../CsrfToken';

const toggleSubTypesDropdown = (sub_types, dropdown) => {
  if (sub_types != null) {
    dropdown.classList.remove('hidden');
  } else {
    dropdown.classList.add('hidden');
  }
};

const removeOldOptions = (dropdownContainer) => {
  const selectedSubType = document.querySelector('#selected_sub_type');
  const selectedSubTypeValue = document.querySelector('#sub-type-value');
  selectedSubType.innerText = i18n.t('admin.filter.no_selection_option');
  selectedSubTypeValue.setAttribute('value', ' ');
  dropdownContainer.innerHTML = ' ';
};

const addDefaultSubTypeOptions = (dropdownContainer) => {
  const hiddenInput = document.createElement('input');
  const selectOption = document.createElement('div');

  dropdownContainer.appendChild(selectOption);
  hiddenInput.setAttribute('name', 'sub_type');
  hiddenInput.setAttribute('hidden', true);
  selectOption.classList.add('item');
  selectOption.innerText = i18n.t('admin.filter.no_selection_option');
};

const addSubtTypes = (sub_types, dropdownContainer) => {
  sub_types.forEach((value) => {
    createSubTypeOption(value, dropdownContainer);
  });
};

const createSubTypeOption = (sub_type, container) => {
  const typeInput = document.createElement('div');

  container.appendChild(typeInput);
  typeInput.setAttribute('value', sub_type);
  typeInput.classList.add('item');
  typeInput.innerText = titleize(sub_type);
};

const init = () => {
  const filterButton = document.querySelector('#financial-transaction-button');
  const mainInput = document.querySelector('#main-type-selection');
  const subTypeDropdown = document.querySelector('#sub_type_dropdown_container');
  const subTypeOptionContainer = document.querySelector('#sub_type_dropdown');

  if (filterButton) {
    filterButton.addEventListener('click', () => {
      showModal('#financial-transactions-filter-modal');
    });

    mainInput.addEventListener('change', (e) => {
      if (e.currentTarget.value != '') {
        $.ajax({
          url: '/api/admin/financial_transaction_types/',
          type: 'GET',
          dataType: 'script',
          data: { main_type: e.currentTarget.value },
          headers: {
            'X-CSRF-Token': getCSRFTokenValue(),
          },
          success(data) {
            const typeResults = JSON.parse(data);
            toggleSubTypesDropdown(typeResults, subTypeDropdown);
            if (!subTypeDropdown.classList.contains('hidden')) {
              removeOldOptions(subTypeOptionContainer);
              addDefaultSubTypeOptions(subTypeOptionContainer);
              addSubtTypes(typeResults, subTypeOptionContainer);
            }
          },
        });
      }
    });
  }
};

document.addEventListener('DOMContentLoaded', init);
