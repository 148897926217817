import $ from 'jquery';
import { showModal } from '../Modal';
import { getCSRFTokenValue } from '../CsrfToken';
import toastr from '../../plugins/Toastr';

const initAccountApprovalEvents = () => {
  const url = document.querySelector('#manage-account-approvals').dataset.approvalToggleUrl;
  $('.approval-form-toggle').on('change', (e) => {
    const el = e.target;
    const typeId = el.id.split('-').pop();
    const typeName = ` -- ${el.getAttribute('data-type-name')}`;

    if (el.checked) {
      const headerSelector = $('#activate-type .header');
      const header = headerSelector.text().split('-')[0] + typeName;
      headerSelector.text(header);

      $('#activate-submit').attr('data-type-id', typeId);
      $('#activate-cancel').attr('data-type-id', typeId);

      showModal('#activate-type');
    } else {
      const headerSelector = $('#deactivate-type .header');
      const header = headerSelector.text().split('-')[0] + typeName;
      headerSelector.text(header);

      $('#deactivate-submit').attr('data-type-id', typeId);
      $('#deactivate-cancel').attr('data-type-id', typeId);

      showModal('#deactivate-type');
    }
  });

  $('#activate-submit').on('click', (e) => {
    $('#activate-type').modal('hide');
    const el = e.target;
    const typeId = el.getAttribute('data-type-id');

    $.ajax({
      url: `${url}/${typeId}`,
      type: 'PUT',
      data: { active: true },
      headers: {
        'X-CSRF-Token': getCSRFTokenValue(),
      },
      error(data) {
        toastr.error(data.responseJSON.error);
      },
    });
  });

  $('#activate-cancel').on('click', (e) => {
    const typeId = e.target.getAttribute('data-type-id');
    $(`#approval-form-toggle-${typeId}`).prop('checked', false);
    $('#activate-type').modal('hide');
  });

  $('#deactivate-submit').on('click', (e) => {
    $('#deactivate-type').modal('hide');
    const typeId = e.target.getAttribute('data-type-id');

    $.ajax({
      url: `${url}/${typeId}`,
      type: 'PUT',
      data: { active: false },
      headers: {
        'X-CSRF-Token': getCSRFTokenValue(),
      },
      error(data) {
        toastr.error(data.responseJSON.error);
      },
    });
  });

  $('#deactivate-cancel').on('click', (e) => {
    const typeId = e.target.getAttribute('data-type-id');
    $(`#approval-form-toggle-${typeId}`).prop('checked', true);
    $('#deactivate-type').modal('hide');
  });
};

const initOrgApprovalEvents = () => {
  const url = document.querySelector('#manage-org-approvals').dataset.approvalToggleUrl;

  $('.org-approval-form-toggle').on('change', (e) => {
    const el = e.target;
    const typeId = el.id.split('-').pop();

    $.ajax({
      context: el,
      url: `${url}/${typeId}`,
      type: 'PUT',
      data: { active: el.checked },
      headers: {
        'X-CSRF-Token': getCSRFTokenValue(),
      },
      success(data) {
        el.id = `org-approval-form-toggle-${data.id}`;
      },
      error(data) {
        toastr.error(data.responseJSON.error);
      },
    });
  });
};

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#manage-account-approvals')) {
    initAccountApprovalEvents();
  }

  if (document.querySelector('#manage-org-approvals')) {
    initOrgApprovalEvents();
  }
});
