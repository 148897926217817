import '@splidejs/splide/dist/css/splide.min.css';

import Splide from '@splidejs/splide';

const defaultOptions = {
  type: 'loop',
  cover: true,
  start: 0,
  perPage: 1,
};

export const initSplide = (selector, options = {}) => {
  new Splide(selector, { ...defaultOptions, ...options }).mount();
};
window.initSplide = initSplide;
