import $ from 'jquery';
import { showModal } from '../Modal';
import { getCSRFTokenValue } from '../CsrfToken';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.portal-toggle')) {
    const toggle = document.querySelector('.portal-toggle');

    $(toggle).on('click', (event) => {
      event.preventDefault();
      const isChecked = event.currentTarget.checked;
      if (isChecked === false && document.querySelector('#portal_toggle_confirm')) {
        showModal('#portal_toggle_confirm');
      } else {
        $.ajax({
          url: toggle.dataset.toggleUrl,
          type: 'POST',
          data: {
            value: toggle.checked,
          },
          headers: {
            'X-CSRF-Token': getCSRFTokenValue(),
          },
          success() {
            $(toggle).prop('checked', isChecked);
          },
        });
      }
      return false;
    });

    $('#portal-confirm').on('click', (event) => {
      event.preventDefault();
      $.ajax({
        url: toggle.dataset.toggleUrl,
        type: 'POST',
        headers: {
          'X-CSRF-Token': getCSRFTokenValue(),
        },
        success() {
          $(toggle).prop('checked', false);
          $('.ui.modal').modal('hide');
        },
      });
      return false;
    });
  }
});
