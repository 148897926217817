import { initCustomCheckboxes } from '../../plugins/FormFields';

const init = () => {
  initCustomCheckboxes();
};

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#manage-email-configuration')) {
    init();
  }
});
