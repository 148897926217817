import $ from 'jquery';

export const initTableShadow = (id) => {
  const setTableShadow = (tId) => {
    const tableView = document.getElementById(tId);
    const tableElement = $(`#${tId}`);
    if (tableView !== null) {
      tableElement.parent()
        .find('.gr-table__item.left')
        .toggleClass('scrolledLeft', tableView.scrollLeft > 10);
      tableElement.parent()
        .find('.gr-table__item.right')
        .toggleClass('scrolledRight', tableView.scrollWidth - tableView.clientWidth - tableView.scrollLeft > 10);
    }
  };

  $(`#${id}`).on('scroll', () => {
    setTableShadow(id);
  });

  setTableShadow(id);
};
window.initTableShadow = initTableShadow;

const init = () => {
  document.querySelectorAll('.table-shadow').forEach((table) => {
    initTableShadow(table.id);
  });
};

document.addEventListener('DOMContentLoaded', init);
