import $ from 'jquery';
import { getCSRFTokenValue } from './CsrfToken';

export const initCookiesAcceptance = () => {
  $.ajax({
    url: '/cookie_acceptances',
    type: 'get',
    dataType: 'JSON',
    headers: {
      'X-CSRF-Token': getCSRFTokenValue(),
    },
    success(data) {
      if (!data.accepted) {
        $('#cookies-acceptance').addClass('show');
      }
    },
  });

  $('#cookies-acceptance .button.deny').on('click', () => {
    $.ajax({
      url: '/cookie_acceptances/new',
      type: 'get',
      dataType: 'JSON',
      headers: {
        'X-CSRF-Token': getCSRFTokenValue(),
      },
      success() {
        $('#cookies-acceptance').removeClass('show');
      },
    });
  });
};
