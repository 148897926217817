import $ from 'jquery';
import { getCSRFTokenValue } from '../../CsrfToken';

const initHotelModalSubmit = () => {
  $('.hotel-edit-submit').off('click').on('click', (e) => {
    const url = $(e.target).data('updateUrl');
    const curatedPropertyId = $(e.target).attr('data-curatedPropertyId');
    const numberOfRooms = $(`#number_of_rooms_${curatedPropertyId}`)[0].value;
    const cutOffDate = $(`#cut_off_date_${curatedPropertyId}`)[0].value;
    const dealDescription = $(`#deal_description_${curatedPropertyId}`)[0].value;

    $(e.target).parents('.modal').modal('hide');

    $.ajax({
      url,
      type: 'PUT',
      data: {
        id: curatedPropertyId,
        curated_property: {
          number_of_rooms: numberOfRooms,
          cut_off_date: cutOffDate,
          deal_description: dealDescription,
        },
      },
      headers: {
        'X-CSRF-Token': getCSRFTokenValue(),
      },
    });
  });
};
window.initialize_hotel_modal_submit = initHotelModalSubmit;

const init = () => {
  if (document.querySelector('.hotel-edit-submit')) {
    initHotelModalSubmit();
  }
};

document.addEventListener('DOMContentLoaded', init);
document.addEventListener('hotelcuration:init', init);
