import $ from 'jquery';
import videojs from 'video.js/dist/alt/video.core';

const scaleVideoOnResize = () => {
  const videoArea = $('.video_area');

  const scalevideo = (videoContent, videoWrapper) => {
    // Got the wrapper video height and width
    const areaWidth = videoWrapper.outerWidth();
    const areaHeight = videoWrapper.outerHeight();

    // Got the native video height and width
    const videoNativeWidth = videoContent[0].videoWidth;
    const videoNativeHeight = videoContent[0].videoHeight;

    // Got the scale factor
    const widthScaleFactor = areaWidth / videoNativeWidth;
    const heightScaleFactor = areaHeight / videoNativeHeight;

    // Got the highest scale factor
    const scale = (widthScaleFactor > heightScaleFactor) ? widthScaleFactor : heightScaleFactor;

    // Got the scaled video width and height
    const scaledVideoWidth = videoNativeWidth * scale;
    const scaledVideoHeight = videoNativeHeight * scale;

    // Set the new height and width to videoContent
    videoContent.width(scaledVideoWidth);
    videoContent.height(scaledVideoHeight);

    // Position video at the center
    const diffWidth = scaledVideoWidth - areaWidth;
    const diffHeight = scaledVideoHeight - areaHeight;

    // Calculate negative positions
    const videoLeft = (diffWidth > 0) ? (-1) * diffWidth / 2 : 0;
    const videoTop = (diffHeight > 0) ? (-1) * diffHeight / 2 : 0;

    // Set new styles
    videoContent.css('left', videoLeft);
    videoContent.css('top', videoTop);

    videoArea.animate({ opacity: 1 }, 500);
  };

  if (videoArea.length > 0) {
    // eslint-disable-next-line func-names
    videojs('background_video').ready(function () {
      const video = this;
      const poster = videoArea.parent().find('.background_image_wrapper');
      const content = $('#background_video video');
      const wrapper = $('section.event-landing');

      video.play();

      content.on('loadedmetadata', () => {
        scalevideo(content, wrapper);
        poster.fadeOut(300);
      });

      $(window).on('resize load', () => {
        scalevideo(content, wrapper);
      });
    });
  }
};

const init = () => {
  if (document.querySelector('.video_area')) {
    scaleVideoOnResize();
  }
};

document.addEventListener('DOMContentLoaded', init);
