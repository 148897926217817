import $ from 'jquery';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';

const railsTimezone = require('rails-timezone');

const setShortTimezone = (target, selectedOptionText) => {
  const text = selectedOptionText || target.text();
  const index = text.indexOf(') ');
  const content = text.substring(0, index + 1);

  if (index > -1) {
    target.text(content);
  }
};

export const updateTimes = () => {
  if (document.querySelectorAll('[data-dt-utc]').length) {
    const tz = railsTimezone.from(Cookies.get('timezone'));
    if (tz != null) {
      document.querySelectorAll('[data-dt-utc]').forEach((el) => {
        const formatString = el.dataset.dtFormat || 'ddd MMM D h:m A';
        const time = moment.utc(el.dataset.dtUtc).tz(tz).format(formatString);
        if (time !== null) {
          el.innerHTML = time;
        }
      });

      document.querySelectorAll('[data-dt-show-timezone]').forEach((el) => {
        const abbr = moment.tz(tz).format('z');
        el.innerHTML = abbr;
      });
    }
  }
};

export const initTimezoneScripts = () => {
  const displayTimes = (zone) => {
    if (zone === null) {
      const guessedTimezone = moment.tz.guess();
      const tz = railsTimezone.to(guessedTimezone);
      Cookies.set('timezone', tz);
    }
    setShortTimezone($('.js_time_zone_dropdown .text'));
  };

  document.querySelector('.js_time_zone_dropdown').addEventListener('change', (e) => {
    const tz = $(e.target).val();
    Cookies.set('timezone', tz);
    setShortTimezone($('.js_time_zone_dropdown .text'), $(e.target)[0].selectedOptions[0].text);
    updateTimes();
  });
  displayTimes(Cookies.get('timezone'));
};

const init = () => {
  if (document.querySelector('.js_time_zone_dropdown')) {
    initTimezoneScripts();
  }
  updateTimes();
};

// This is dependent on the semantic dropdowns being defined before this runs
document.addEventListener('semanticui:initialized', init);
