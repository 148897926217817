import { initializeSemanticUI } from '../../plugins/SemanticUI';
import { initCustomSelects } from '../../plugins/FormFields';

const init = () => {
  initializeSemanticUI();
  initCustomSelects();
};

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#concur-travel-management')) {
    init();
  }
});
