import $ from 'jquery';
import { getCSRFTokenValue } from '../CsrfToken';

const initializeEventTypeManagement = () => {
  $('.remove-type').on('click', (e) => {
    const typeId = e.target.dataset.eventTypeId;

    $(`#deactivate-event-type-modal-${typeId}`)
      .modal({
        onApprove() {
          $.ajax({
            url: `event_types/${typeId}`,
            type: 'DELETE',
            dataType: 'script',
            headers: {
              'X-CSRF-Token': getCSRFTokenValue(),
            },
          });
        },
      })
      .modal('show');
  });
};

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#event_types_listing')) {
    initializeEventTypeManagement();
  }
});

document.addEventListener('event_type_management:init', () => {
  initializeEventTypeManagement();
});
