import { Controller } from "@hotwired/stimulus";
import { initAllFlatpickrMulti } from "../../src/custom/DatetimeInit";

export default class extends Controller {
  static targets = ["dates"];

  connect() {
    initAllFlatpickrMulti();
  }

  changePresetDates(event) {
    if (event.target.checked) {
      this.datesTarget.classList.remove("tw-hidden");
    } else {
      this.datesTarget.classList.add("tw-hidden");
    }
  }
}
