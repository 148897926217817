import $ from 'jquery';
import { getCSRFTokenValue } from './CsrfToken';

const initMemberOrganizations = () => {
  $('.remove-member').on('click', (e) => {
    const el = e.currentTarget;
    const memberId = el.id.split('-').pop();

    $(`#remove-member-modal-${memberId}`)
      .modal({
        onApprove() {
          const notifyCheckbox = false;
          let notify = null;
          if (notifyCheckbox) {
            notify = notifyCheckbox.checked;
          }
          $.ajax({
            url: `members/${memberId}`,
            dataType: 'script',
            type: 'DELETE',
            data: { notify },
            headers: {
              'X-CSRF-Token': getCSRFTokenValue(),
            },
          });
        },
      })
      .modal('show');
  });
};

document.addEventListener('DOMContentLoaded', () => {
  initMemberOrganizations();
});
