import $ from "jquery";

const initRegistrationEvents = () => {
  const editRegistrationId = $("#edit_registration_id").val();
  const eventId = $("#event_id").val();
  const organizationId = $("#organization_id").val();

  if (editRegistrationId && eventId && organizationId) {
    $.ajax({
      url: `/organizations/${organizationId}/events/${eventId}/admin/registrations/${editRegistrationId}/booked_sessions`,
      type: "GET",
      dataType: "script",
    });
  }

  const overCapacity = $("#over_capacity").val();

  if (overCapacity) {
    $("#over_capacity_modal").modal("show");
  }
};

const init = () => {
  const registrationListing = document.querySelector(
    "[data-registration-listing]",
  );
  if (registrationListing) {
    initRegistrationEvents();
  }
};

document.addEventListener("DOMContentLoaded", init);
