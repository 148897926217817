import $ from 'jquery';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('a#remove-addendum')) {
    $('a#remove-addendum').on('click', (event) => {
      event.preventDefault();
      $('input#branding_configuration_addendum_name').val(null);
      $('input#branding_configuration_addendum_url').val(null);
      $(this).hide();
      $('a#preview-addendum').hide();
      $('a#remove-addendum').hide();
    });
  }
});
