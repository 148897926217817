const setState = (select) => {
  const hideClass = select.dataset.conditionalSelectContainer;
  document.querySelectorAll(hideClass).forEach((container) => {
    if (container.classList.contains(`${select.value}`)) {
      container.style.display = 'block';
    } else {
      container.style.display = 'none';
    }
  });
};

const selectChanged = (ev) => {
  const select = ev.currentTarget;
  setState(select);
};

const init = () => {
  document.querySelectorAll('.conditional-select').forEach((select) => {
    setState(select);
    select.addEventListener('change', selectChanged);
  });
};

document.addEventListener('DOMContentLoaded', init);
