import { Controller } from "@hotwired/stimulus";
import { i18n } from "../../src/custom/Internationalization";

export default class extends Controller {
  static targets = ["notice", "selector", "all", "allPages", "pagination"];

  connect() {
    const selectedAll = document.getElementById("selected_all");
    if (selectedAll.value === "true") {
      this.selectAllEveryPage();
    }
  }

  toggle() {
    const allCheckboxCount = this.selectorTargets.length;
    const checkedCount = this.selectorTargets.filter(
      (checkbox) => checkbox.checked,
    ).length;

    this.clearPaginationParam();
    this.clearSelectedAll();

    if (allCheckboxCount === checkedCount) {
      this.allTarget.checked = true;
    } else {
      this.allTarget.checked = false;
    }

    this.setCheckedCount();
  }
  clearSelectedAll() {
    const selectedAll = document.getElementById("selected_all");
    selectedAll.value = false;
  }

  selectAllOnPage() {
    this.clearPaginationParam();

    this.selectorTargets.forEach((checkbox) => {
      checkbox.checked = this.allTarget.checked;
    });

    this.clearSelectedAll();
    this.setCheckedCount();
  }

  selectAllEveryPage() {
    const selectedAll = document.getElementById("selected_all");
    selectedAll.value = true;
    this.allPagesTarget.classList.add("tw-hidden");

    this.allTarget.checked = true;
    this.selectorTargets.forEach((checkbox) => {
      checkbox.checked = true;
    });

    if (this.hasNoticeTarget) {
      this.noticeTarget.innerText = i18n.t("notices.all_pages_selected", {
        count: this.allPagesTarget.dataset.totalRecordCount,
      });
    }
    this.setPaginationParam();
  }

  setCheckedCount() {
    const allCheckboxCount = this.selectorTargets.length;
    const checkedCount = this.selectorTargets.filter(
      (checkbox) => checkbox.checked,
    ).length;

    if (this.hasNoticeTarget) {
      if (checkedCount > 0) {
        this.noticeTarget.innerText = i18n.t("registrations.notices.selected", {
          count: checkedCount,
        });
      } else {
        this.noticeTarget.innerText = "";
      }
    }

    const totalRecordCount = parseInt(
      this.allPagesTarget.dataset.totalRecordCount,
      10,
    );

    if (
      allCheckboxCount === checkedCount &&
      totalRecordCount > allCheckboxCount
    ) {
      this.allPagesTarget.classList.remove("tw-hidden");
    } else {
      this.allPagesTarget.classList.add("tw-hidden");
    }
  }

  setPaginationParam() {
    if (this.hasPaginationTarget) {
      this.paginationTarget.querySelectorAll("a").forEach((a) => {
        const url = new URL(a.href);
        url.searchParams.set("selected_all", "true");
        a.href = url.toString();
      });
    }
  }

  clearPaginationParam() {
    if (this.hasPaginationTarget) {
      this.paginationTarget.querySelectorAll("a").forEach((a) => {
        const url = new URL(a.href);
        url.searchParams.delete("selected_all");
        a.href = url.toString();
      });
    }
  }
}
