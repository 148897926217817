import $ from 'jquery';
import { initTableShadow } from '../TableShadow';
import { showModal } from '../Modal';
import { setBulkActionsStatus } from '../batch_actions';
import { initCustomCheckboxes } from '../../plugins/FormFields';

const initMemberTable = () => {
  initTableShadow('members_table_view');
  const $table = $('#members_table_view').parents('.bulk-table');
  const $rows = $table.find('.select_row');
  $rows.checkbox({
    onChecked() {
      const $parentRowId = $(this).parents('.gr-table-row').data('id');
      const $parentRowSections = $(`.member_${$parentRowId}`);
      $parentRowSections.each((i, elem) => {
        $(elem).addClass('selected');
      });
      setBulkActionsStatus($('.tab.active .bulk-container'));
    },
    onUnchecked() {
      const $parentRowId = $(this).parents('.gr-table-row').data('id');
      const $parentRowSections = $(`.member_${$parentRowId}`);
      $parentRowSections.each((i, elem) => {
        $(elem).removeClass('selected');
      });
      setBulkActionsStatus($('.tab.active .bulk-container'));
    },
  });
};

const initDomainsTable = () => {
  initTableShadow('domains_table_view');

  const $table = $('#domains_table_view').parents('.bulk-table');
  const $rows = $table.find('.select_row');

  $rows.checkbox({
    onChecked() {
      const $parentRowId = $(this).parents('.gr-table-row').data('id');
      const $parentRowSections = $(`.member_${$parentRowId}`);
      $parentRowSections.each((i, elem) => {
        $(elem).addClass('selected');
      });
      setBulkActionsStatus($('.tab.active .bulk-container'));
    },
    onUnchecked() {
      const $parentRowId = $(this).parents('.gr-table-row').data('id');
      const $parentRowSections = $(`.member_${$parentRowId}`);
      $parentRowSections.each((i, elem) => {
        $(elem).removeClass('selected');
      });
      setBulkActionsStatus($('.tab.active .bulk-container'));
    },
  });
};

const initMemberSearch = () => {
  $('#members-search').on('click', () => {
    showModal('#members-search-modal');
    initCustomCheckboxes();
  });
};

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#members_table_view')) {
    initMemberTable();
  }

  if (document.querySelector('#domains_table_view')) {
    initDomainsTable();
  }

  if (document.querySelector('#members-search')) {
    initMemberSearch();
  }
});
