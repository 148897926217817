import $ from "jquery";
import { bindStartEndDateFields } from "../DatetimeInit";
import { initCustomCheckboxes } from "../../plugins/FormFields";
import { initializeSemanticUI } from "../../plugins/SemanticUI";
import { initLocationAutocomplete } from "../../plugins/LocationAutocomplete";

const initializeEventTypesDropdown = () => {
  $('select[name="event[organization_id]"]').on("change", (e) => {
    const organizationId = e.currentTarget.value;
    const eventTypesDropdown = $('select[name="event[event_type_id]"]');

    $.getJSON(`/admin/organizations/${organizationId}/event_types`, (data) => {
      eventTypesDropdown.empty();

      if (data.length === 0) {
        const option = '<option value="unknown">No Event Types</option>';
        eventTypesDropdown.append(option);

        if (eventTypesDropdown.parents(".dropdown").length === 1) {
          $(eventTypesDropdown.parents(".dropdown")[0]).addClass("disabled");
        }
      } else {
        if (eventTypesDropdown.parents(".dropdown").length === 1) {
          $(eventTypesDropdown.parents(".dropdown")[0]).removeClass("disabled");
        }

        $.each(data, (index, value) => {
          const option = `<option value="${value.id}">${value.name}</option>`;
          eventTypesDropdown.append(option);
        });
      }
    });
  });
};

const init = () => {
  if (document.querySelector("[data-create-event]")) {
    initializeSemanticUI();
    initCustomCheckboxes();
    initializeEventTypesDropdown();
    bindStartEndDateFields();
    initLocationAutocomplete();
  }
};

document.addEventListener("DOMContentLoaded", init);
document.addEventListener("createevent:init", init);
