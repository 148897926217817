import normalizeUrl from "normalize-url";
import Quill from "quill";
import QuillFilestackUploader from "./QuillFilestackUploader";
import { i18n } from "../custom/Internationalization";
import { te } from "date-fns/locale";
import { update } from "lodash";

export const initWysiwygEditor = (el, ph = null) => {
  if (el.classList.contains("activated")) {
    return;
  }
  el.classList.add("activated");

  const placeholder =
    ph || el.dataset.placeholder || i18n.t("placeholders.type_here");

  const quill = new Quill(`#${el.id}`, {
    modules: {
      toolbar: `#toolbar-${el.id}`,
      quillFilestackUploader: {
        filestack: window.fpGroupize,
      },
    },
    placeholder,
    theme: "snow",
  });

  const editor = quill.container.querySelector(".ql-editor");
  if (editor && el.dataset.themeClass) {
    editor.classList.add(el.dataset.themeClass);
  }

  const colorToggle =
    quill.container.parentElement.querySelector(".ql_editor__bg");
  if (colorToggle) {
    colorToggle.addEventListener("change", (e) => {
      e.preventDefault();
      quill.container.classList.toggle("dark_bg");
    });
  }

  const setHiddenFieldText = () => {
    const parent = quill.container.parentElement;
    const field = parent.querySelector("input[type=hidden]");
    if (field) {
      field.value = quill.getSemanticHTML();
    }
  };

  const updateLimit = () => {
    const limit = quill.container.dataset.limit || false;

    if (limit) {
      const textLength = quill.getLength() - 1;
      const limitText = document.querySelector(
        `#limit_${quill.container.id} span`,
      );

      limitText.innerText = limit - textLength;

      if (textLength > limit) {
        quill.deleteText(limit, textLength);
      }
    }
  };

  quill.on("text-change", () => {
    updateLimit();
    setHiddenFieldText();
  });

  updateLimit();
  setHiddenFieldText();
};

export const initEditors = () => {
  document.querySelectorAll(".ql_editor").forEach((el) => {
    initWysiwygEditor(el);
  });
};

const setupLinkSettings = () => {
  const Link = Quill.import("formats/link");
  Link.sanitize = (url) => {
    const lowerUrl = url.toLowerCase();

    const mailLinkPattern = /^(mailto:)?(.+)@+(.+)/g;
    if (mailLinkPattern.test(lowerUrl)) {
      if (!lowerUrl.startsWith("mailto:")) {
        return `mailto:${url}`;
      }
      return url;
    }

    return normalizeUrl(url, {
      defaultProtocol: "https:",
      removeTrailingSlash: false,
      stripWWW: false,
    });
  };
};

const setupFontSettings = () => {
  const Font = Quill.import("formats/font");
  Font.whitelist = [
    "Open Sans",
    "proximanova",
    "sans-serif",
    "serif",
    "monospace",
  ];
  Quill.register(Font, true);
};

const setImageUploaderSettings = () => {
  Quill.register("modules/quillFilestackUploader", QuillFilestackUploader);
};

const init = () => {
  setupLinkSettings();
  setupFontSettings();
  setImageUploaderSettings();
  initEditors();
};

document.addEventListener("DOMContentLoaded", init);
document.addEventListener("quill:rebind", initEditors);
