import { saveAs } from "file-saver";
import consumer from "./consumer";

import toastr from "../../plugins/Toastr";

export const subscribeDownloadChannel = (uuid, callback, postAction) => {
  window.App.download = consumer.subscriptions.create(
    { channel: "ExporterChannel", uuid },
    {
      connected() {
        callback();
      },
      disconnected() {},
      received(data) {
        const filename = data.filename || "download.xlsx";

        // Sometimes this triggers a CORS error but the library has a fallback so do not log it
        saveAs(data.url, filename);

        consumer.subscriptions.remove(window.App.download);
        consumer.disconnect();
        delete window.App.download;

        if (typeof postAction !== "undefined") {
          postAction(uuid, data);
        }
        toastr.clear();
      },
    },
  );
};
