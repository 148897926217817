import $ from 'jquery';

const bindModalDisplay = (link) => {
  $(link).on('click', (e) => {
    e.preventDefault();
    $(`#${link.dataset.confirmModalId}`).modal('show');
    return false;
  });
};

const init = () => {
  document.querySelectorAll('[data-confirm-modal-id]').forEach((el) => {
    bindModalDisplay(el);
  });
};

document.addEventListener('DOMContentLoaded', init);
document.addEventListener('confirm-modal:rebind', init);
