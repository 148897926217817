const toggleSelectAll = (ev) => {
  const selectAllCheckbox = ev.currentTarget;
  const container = selectAllCheckbox.closest(".attendee-type-selector");

  container.querySelectorAll(".attendee-type-checkbox").forEach((checkbox) => {
    if (
      checkbox.dataset.defaultAttendeeType === "true" &&
      selectAllCheckbox.checked === false
    ) {
      checkbox.parentNode.classList.add("disabled");
    } else {
      checkbox.checked = selectAllCheckbox.checked;
      checkbox.parentNode.classList.remove("disabled");
    }
  });
};

const bindSelectAll = (container) => {
  const elem = container.querySelector(".all-attendee-types");
  if (elem) {
    elem.addEventListener("change", toggleSelectAll);
  }
};

const toggleCheckbox = (ev) => {
  const checkbox = ev.currentTarget;
  const container = checkbox.closest(".attendee-type-selector");
  const selectAllCheckbox = container.querySelector(".all-attendee-types");
  let allChecked = true;

  const checkedCount = container.querySelectorAll(
    ".attendee-type-checkbox:checked",
  ).length;
  if (checkedCount === 0) {
    checkbox.checked = true;
    return;
  }

  container.querySelectorAll(".attendee-type-checkbox").forEach((elem) => {
    if (!elem.checked) {
      allChecked = false;
    }
    elem.parentNode.classList.remove("disabled");
  });

  selectAllCheckbox.checked = allChecked;

  const checked = container.querySelectorAll(".attendee-type-checkbox:checked");
  if (checked.length === 1) {
    checked[0].parentNode.classList.add("disabled");
  }
};

const bindCheckboxes = (container) => {
  container.querySelectorAll(".attendee-type-checkbox").forEach((checkbox) => {
    checkbox.addEventListener("change", toggleCheckbox);
  });
};

const setInitialState = (container) => {
  const selectAllCheckbox = container.querySelector(".all-attendee-types");
  if (selectAllCheckbox === null || selectAllCheckbox.checked) {
    container
      .querySelectorAll(".attendee-type-checkbox")
      .forEach((checkbox) => {
        checkbox.checked = true;
      });
  }
};

export const bindAttendeeTypeContainer = (container) => {
  setInitialState(container);
  bindSelectAll(container);
  bindCheckboxes(container);
};

export const bindAddedAttendeeTypeContainer = (container) => {
  const count = document.querySelectorAll(".attendee-type-selector").length;
  if (container) {
    const selectAllContainer = container.querySelector(".select_all");
    const selectAllCheckbox = selectAllContainer.querySelector(
      ".all-attendee-types",
    );
    const selectAllLabel = selectAllContainer.querySelector(
      ".all-attendee-types-label",
    );

    selectAllCheckbox.setAttribute("id", `new-select-all-${count}`);
    selectAllLabel.setAttribute("for", `new-select-all-${count}`);

    bindAttendeeTypeContainer(container);
  }
};

const init = () => {
  document.querySelectorAll(".attendee-type-selector").forEach((container) => {
    bindAttendeeTypeContainer(container);
  });
};

document.addEventListener("DOMContentLoaded", init);
