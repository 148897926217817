import { showModal } from '../Modal';

const init = () => {
  const filterButton = document.querySelector('#airrequest-filter-button');
  if (filterButton) {
    filterButton.addEventListener('click', () => {
      showModal('#air-requests-filter-modal');
    });
  }
};

document.addEventListener('DOMContentLoaded', init);
