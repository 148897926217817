const initMedproSettingsForm = () => {
  const searchCheckbox = document.querySelector('#medpro_vendor_configuration_search_external_id_format');
  const updateCheckbox = document.querySelector('#medpro_vendor_configuration_update_external_id_format');
  searchCheckbox.addEventListener('change', () => {
    if (searchCheckbox.checked) {
      updateCheckbox.disabled = false;
    } else {
      updateCheckbox.checked = false;
      updateCheckbox.disabled = true;
    }
  });
};

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#medpro_conf_form')) {
    initMedproSettingsForm();
  }
});
