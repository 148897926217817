const init = () => {
  const DiscountForm = document.getElementById('discount_form');
  const hasId = (form) => {
    let id;
    if (form) {
      const actionArray = form.action.split('/');
      id = actionArray[actionArray.length - 1];
    }
    return !Number.isNaN(Number(id));
  };

  if (DiscountForm && !hasId(DiscountForm)) {
    // New Discount Page
    // Variables
    const discountTypeSelect = document.getElementById('discount_percent_value');
    const fixedAmountOption = document.getElementById('discount_amount_off');
    const percentOffOption = document.getElementById('discount_percent_off');
    const discountApplicationSelect = document.getElementById('discount_applies_to');
    const ticketList = document.getElementsByName('discount[applies_to_tickets][]');
    const ticketContainer = document.getElementById('discount_tickets_container');
    const currencyContainer = document.getElementById('discount_currency');
    const codesContainer = document.getElementById('discount_codes_container');
    const addCodeButton = document.getElementById('secondary_add_code_btn');
    const codeField = document.getElementById('discount_code_names');
    const error = document.querySelector('[data-error="field-error"]');
    const errorObj = error ? JSON.parse(error.dataset.fieldErrorContent) : null;

    // Functions
    const displayError = (obj) => {
      if (obj) {
        const field = document.querySelector(`input[name="discount[${obj.field}]"]`)
          || document.querySelector(`input[name="discount[${obj.field}][]"]`);
        const errorText = document.querySelector(`[data-error="${obj.field}"]`);

        if (field.classList.contains('flatpickr')) {
          const fieldWrapper = field.closest('.gr-input__input');
          fieldWrapper.classList.add('error');
        }

        if (field && errorText) {
          errorText.innerHTML = obj.message;
          errorText.classList.remove('hide');
          errorText.classList.add('tw-text-red-500');
          field.classList.add('error');
        }
      }
    };
    const discountType = (option) => {
      if (typeof option === 'boolean') {
        if (option) {
          // True - Percent On
          fixedAmountOption.parentNode.classList.add('hide');
          fixedAmountOption.classList.add('hide');
          currencyContainer.classList.add('hide');
          percentOffOption.parentNode.classList.remove('hide');
          percentOffOption.classList.remove('hide');
        } else {
          // False - Fixed Amount On
          fixedAmountOption.parentNode.classList.remove('hide');
          fixedAmountOption.classList.remove('hide');
          currencyContainer.classList.remove('hide');
          percentOffOption.parentNode.classList.add('hide');
          percentOffOption.classList.add('hide');
        }
      }
    };

    const discountApplication = (option) => {
      if (typeof option === 'boolean' && ticketList.length) {
        if (option) {
          // True - Applies to all
          ticketContainer.classList.add('hide');
          ticketList.forEach((ticket) => {
            ticket.checked = true;
          });
        } else {
          // False - Applies to specific - checked only
          ticketContainer.classList.remove('hide');
        }
      }
    };

    const addCodeField = () => {
      const wrapper = codeField.parentNode.cloneNode(true);
      const field = wrapper.children.namedItem('discount_code_names');
      const trashBtn = document.createElement('i');
      trashBtn.classList.add('icon', 'trash', 'alternate', 'ml-1', 'mt-1', 'button');
      const rowBreak = document.createElement('br');

      field.removeAttribute('id');
      field.value = '';

      if (field.classList.contains('error')) {
        field.classList.remove('error');
      }

      wrapper.appendChild(trashBtn);
      codesContainer.appendChild(rowBreak);
      codesContainer.appendChild(wrapper);

      trashBtn.addEventListener('click', () => {
        wrapper.remove();
        rowBreak.remove();
      });
    };

    // Default
    discountType(JSON.parse(discountTypeSelect.value));
    discountApplication(JSON.parse(discountApplicationSelect.value));
    displayError(errorObj);

    // Listeners
    discountTypeSelect.addEventListener('change', (event) => {
      discountType(JSON.parse(event.target.value));
    });
    discountApplicationSelect.addEventListener('change', (event) => {
      discountApplication(JSON.parse(event.target.value));
    });
    addCodeButton.addEventListener('click', () => {
      addCodeField();
    });
  }
};

document.addEventListener('DOMContentLoaded', init);
