import { clearUrlParameterAndReload, clearMultiUrlParameterAndReload } from '../UrlParams';

const bindClearFilter = (button) => {
  button.addEventListener('click', (event) => {
    const el = event.currentTarget;
    if (el.dataset.filterName) {
      if (el.dataset.filterValue) {
        clearMultiUrlParameterAndReload(el.dataset.filterName, el.dataset.filterValue);
      } else {
        clearUrlParameterAndReload(event.currentTarget.dataset.filterName);
      }
    }
  });
};

const init = () => {
  document.querySelectorAll('.clear-filter').forEach((el) => {
    bindClearFilter(el);
  });
};

document.addEventListener('DOMContentLoaded', init);
