import { i18n } from './Internationalization';

export const html = (header, message) => `\
  <div class="ui modal" id="confirmationDialog">
    <i class="close icon"></i>
    <div class="header">
      ${header}
    </div>
    <div class="content">
      <p>${message}</p>
    </div>
    <div class="actions left">
      <a class="ui basic deny button">${i18n.t('actions.cancel')}</a>
      <a class="ui basic green right labeled icon button approve">
        ${i18n.t('actions.confirm')}
        <i class="checkmark icon"></i>
      </a>
    </div>
  </div>\
  `;
