import $ from 'jquery';

const syncText = (e) => {
  var selector = e.dataset.matchingInputDisplay;
  const display = document.querySelector(selector);
  display.innerText = e.value;
}
const init = () => {
  document.querySelectorAll('[data-matching-input-display]').forEach((el) => {
    el.addEventListener('keyup', function() {
        syncText(el);
    });
  });
  $('#app_sections, #website_sections').on('cocoon:after-insert', (e, insertedItem, originalEvent) => {
    var sectionCount = $('.nested-fields').length;
    $(insertedItem).find('.section_item__title').addClass("js-display-" + sectionCount);
    $(insertedItem).find('[data-matching-input-display]').each(function(index) {
      this.dataset.matchingInputDisplay = ".js-display-" + sectionCount;
      $(this).on('keyup', function() {
        syncText(this); 
      });
    });
  });
}

document.addEventListener('DOMContentLoaded', init);