import $ from 'jquery';

const initFlightConfigurations = () => {
  function checkNumber() {
    const numberOfCheckedOptions = document.querySelectorAll('[data-air-choice]:checked').length;

    $('.air_skippable.checkbox').toggleClass('disabled', (numberOfCheckedOptions < 1));
    $('.air_skippable.checkbox input[type=checkbox]').prop('disabled', (numberOfCheckedOptions < 1));
    if (numberOfCheckedOptions < 1) {
      $('.air_skippable.checkbox input[type=checkbox]').prop('checked', false);
    }
  }

  document.querySelectorAll('[data-air-choice]').forEach((elem) => {
    elem.addEventListener('change', () => {
      checkNumber();
    });
  });

  checkNumber();
};

export const checkDisplayWithMandatoryFields = (containerSelector = '#reg_form_update_container') => {
  const $mandatoryField = $(`${containerSelector} input[type="checkbox"][name$="[required]"]`);
  const $displayField = $(`${containerSelector} input[type="checkbox"][name$="[active]"]`);

  $mandatoryField.on('click', (e) => {
    if ($(e.target).is(':checked')) {
      const displayField = $($(e.target).closest('td')).prev('td').find('input[type="checkbox"][name$="[active]"]');
      displayField.prop('checked', true);
    }
  });

  $displayField.on('click', (e) => {
    if (!$(e.target).is(':checked')) {
      const requiredField = $($(e.target).closest('td')).next('td').find('input[type="checkbox"][name$="[required]"]');
      requiredField.prop('checked', false);
    }
  });
};

const init = () => {
  if (document.querySelector('[data-flight-configurations]')) {
    initFlightConfigurations();
  }

  if (document.querySelector('[data-flight-fields]')) {
    checkDisplayWithMandatoryFields('#air_request_fields_table');
  }
};

document.addEventListener('DOMContentLoaded', init);
