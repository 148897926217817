import Swiper from 'swiper/swiper-bundle.esm';

const defaultOptions = {
  direction: 'horizontal',
  loop: true,
  nextButton: '.swiper-button-next',
  prevButton: '.swiper-button-prev',
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
  },
};

export const initSwiper = (selector, options = defaultOptions) => new Swiper(selector, options);

document.addEventListener('swiper:init', (e) => {
  initSwiper(e?.detail?.selector, e?.detail?.options);
});
