const bindFilteredTextBox = (elem) => {
  elem.addEventListener('keydown', (e) => {
    const pattern = new RegExp(e.currentTarget.dataset.filterPattern);
    const result = pattern.test(e.key);
    if (!result) {
      e.preventDefault();
      return false;
    }
    return true;
  });
};

const init = () => {
  document.querySelectorAll('[data-filter-pattern]').forEach((elem) => {
    bindFilteredTextBox(elem);
  });
};

document.addEventListener('DOMContentLoaded', init);
