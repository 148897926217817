const init = () => {
  if (!document.querySelector('[data-transaction-management]')) {
    return;
  }

  document.querySelector('#save_and_pay')?.addEventListener('click', (e) => {
    e.preventDefault();
    document.querySelector('#pay_toggle').value = '1';
    const form = document.getElementById(e.currentTarget.getAttribute('form'));
    form.submit();
  });
};
document.addEventListener('DOMContentLoaded', init);
