document.addEventListener('DOMContentLoaded', () => {
  const menu = document.getElementById('menu');

  if (menu) {
    const menuIcon = menu.querySelector('.menu__icon');
    const menuContent = menu.querySelector('.menu__content');
    const menuCloseIcon = menu.querySelector('.menu__icon.close');

    if (menuCloseIcon) {
      menuCloseIcon.onclick = () => {
        menuContent.classList.toggle('show');
      };
    }

    if (menuIcon) {
      menuIcon.onclick = () => {
        menuContent.classList.toggle('show');
      };
    }

    document.dispatchEvent(new Event('accountpicker:init'));
  }
});
