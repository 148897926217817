import $ from "jquery";

const initializeExpertAssistActions = () => {
  $(".deactivate-expert-assist").on("change", (e) => {
    $(".expert_assist_input").prop("disabled", $(e.target).prop("checked"));
  });
};

document.addEventListener("expertassistmanagement:init", () => {
  initializeExpertAssistActions();
});
