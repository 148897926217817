import $ from 'jquery';
import { showModal } from '../Modal';
import { initializeSemanticUI } from '../../plugins/SemanticUI';
import { initCustomCheckboxes } from '../../plugins/FormFields';
import { getCSRFTokenValue } from '../CsrfToken';

const init = () => {
  $('.js-show-filter').on('click', (e) => {
    e.preventDefault();
    const modalId = $(e.currentTarget).attr('data-modal');
    showModal(`#${modalId}`);
  });

  const setBulkActionsStatus = () => {
    const hasSelectedItems = $('.reg_table__row.selected').length > 0;
    $('#event_bulk_actions').toggleClass('disabled', !hasSelectedItems);
  };

  $('.select_row.select_brand').checkbox({
    onChecked() {
      const $parentRow = $(this).parents('tr');
      const parentRowClass = $parentRow.attr('class').split(' ').map((s) => `.${s}`).join('');
      $(parentRowClass).addClass('selected');
      setBulkActionsStatus();
    },
    onUnchecked() {
      const $parentRow = $(this).parents('tr');
      const parentRowClass = $parentRow.attr('class').split(' ').map((s) => `.${s}`).join('');
      $(parentRowClass).removeClass('selected');
      setBulkActionsStatus();
      document.getElementById('select_all').checked = false;
    },
  });

  const appendData = (name, value) => {
    const tempInput = $(`<input name='${name}'>`).attr('type', 'hidden').attr('value', value);
    $('form#brands-multiple-action-form').append($(tempInput));
  };

  const appendDataSubmit = (name, value) => {
    appendData('page', (window.getUrlParameter('page') || 1));
    appendData(name, value);
    $('form#brands-multiple-action-form').submit();
  };

  function appendFilterParams() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.forEach((value, key) => {
      appendData(key, value);
    });

    if (document.getElementById('select_all').checked) {
      appendData('all', 'true');
    }
  }

  $('#brands-active-menu a.item').on('click', (e) => {
    appendFilterParams();
    appendDataSubmit('set_active', $(e.target).data('activate'));
  });

  $('#brands-preferred-menu a.item').on('click', (e) => {
    appendFilterParams();
    appendDataSubmit('set_preferred', $(e.target).data('preferred'));
  });

  $('#multiple-brand-edit').on('click', () => {
    const previewText = $('#multi-brand-promo-preview').val();
    const promoText = $('#multi-brand-promo > .ql-editor').html();
    appendFilterParams();
    appendData('promo_text', promoText);
    appendDataSubmit('promo_text_preview', previewText);
  });

  $('#multi-contacts-edit-button').on('click', (e) => {
    e.preventDefault();
    const selectedBrands = $('.select_brand > input:checked').map((i, elem) => elem.value).get();
    const accountId = $('.multi_edit_account_id').first().val();

    $.ajax({
      url: 'brand_contacts/multiple_edit',
      type: 'get',
      dataType: 'script',
      data: {
        account_id: accountId,
        brand_ids: selectedBrands,
      },
      headers: {
        'X-CSRF-Token': getCSRFTokenValue(),
      },
    });
  });

  setBulkActionsStatus();
  initializeSemanticUI();
  initCustomCheckboxes();
};

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#brand-management')) {
    init();
  }
});

document.addEventListener('brandmanagement:init', () => {
  init();
});

document.addEventListener('brandmanagement:setSelectedBrands', () => {
  const selectedBrands = $('.select_brand > input:checked').map((i, elem) => elem.value).get();
  const field = document.querySelector('#selected_brands');
  field.value = selectedBrands;
});
