import $ from 'jquery';
import { initSortable } from '../plugins/SortableJS';

const init = () => {
  const container = document.getElementById('additional_options');
  const keyFn = (e) => {
    if (e.key === e.code && e.code === 'Enter') {
      e.preventDefault();
      document.querySelector('.add_fields').click();
    }
  };
  const keyListener = (selector) => {
    selector.forEach((row) => {
      row.addEventListener('keydown', keyFn);
    });
  };

  if (container) {
    const deleteBtns = container.querySelectorAll('.remove_fields.existing');

    const observerOptions = {
      childList: true,
      attributes: true,
      // Omit (or set to false) to observe only changes to the parent node
      subtree: false,
    };
    const listen = () => {
      const answers = container.querySelectorAll('.nested-fields .gr-input__input');
      $('.dropdown:not(.custom)').each((ind, el) => {
        const element = $(el);
        const toTop = element.hasClass('toTop');
        const thisOptions = {
          allowCategorySelection: true,
          refresh: false,
        };

        thisOptions.direction = toTop ? 'upward' : 'auto';
        element.dropdown(thisOptions);

        element.find('.remove.icon').on('click', (e) => {
          e.stopPropagation();
          $(e.target).parent('.dropdown').dropdown('clear');
        });
      });

      keyListener(answers);

      answers[answers.length - 1].focus();
    };
    const observer = new MutationObserver(listen);
    observer.observe(container, observerOptions);

    deleteBtns.forEach((btn) => {
      btn.addEventListener('click', (e) => {
        const { dataset } = e.target;
        // Localstorage check needed for confirmation window
        if (dataset.answerId && localStorage.getItem(dataset.answerId)) {
          const subQuestions = container.querySelectorAll(`.gr-table-row[data-answer-id="${dataset.answerId}"]`);
          if (subQuestions.length) {
            subQuestions.forEach((row) => { row.remove(); });
          }
        }
      });
    });

    keyListener(container.querySelectorAll('.nested-fields .gr-input__input'));

    container.querySelectorAll('[id^="nested"]').forEach((c) => {
      if (c.querySelectorAll('.js-sort-item').length > 0) {
        initSortable(
          c.id,
          '.js-sort-item',
          '.js-sort-item__position',
          '.js-sort-item',
          window.updateSortOrder,
        );
      }
    });
  }
};

document.addEventListener('DOMContentLoaded', init);
