const init = () => {
  document.querySelectorAll('[data-removable-tag-link]').forEach((el) => {
    el.addEventListener('click', (e) => {
      const link = e.currentTarget;
      const tag = link.closest('[data-removable-tag]');
      tag.parentNode.removeChild(tag);
    });
  });
};

document.addEventListener('DOMContentLoaded', init);
document.addEventListener('removabletag:init', init);
