const init = () => {
  if (!document.querySelector('[data-vendor-management]')) {
    return;
  }

  document.querySelector('.sub_type')?.addEventListener('click', () => {
    document.querySelector("[name='financial_transaction[main_type]']").value = this.dataset.mainType;
  });
};
document.addEventListener('DOMContentLoaded', init);
