import $ from 'jquery';
import { getCSRFTokenValue } from '../CsrfToken';
import { initializeSemanticUI } from '../../plugins/SemanticUI';

const initializeAttendeeTypeActions = () => {
  $('.remove-type').on('click', (e) => {
    const typeId = e.currentTarget.id.split('-').pop();

    $(`#deactivate-attendee-type-modal-${typeId}`)
      .modal({
        onApprove() {
          $.ajax({
            url: `attendee_types/${typeId}`,
            type: 'PUT',
            dataType: 'script',
            data: { id: typeId, active: false },
            headers: {
              'X-CSRF-Token': getCSRFTokenValue(),
            },
          });
          return false;
        },
      })
      .modal('show');
  });
};

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#manage-attendee-type-form')) {
    initializeAttendeeTypeActions();
    initializeSemanticUI();
  }
});
