import '@claviska/jquery-minicolors';
import '@claviska/jquery-minicolors/jquery.minicolors.css';
import $ from 'jquery';

const ColorPicker = {
  init() {
    $('input[type=text].minicolors').each((_, el) => {
      $(el).minicolors({
        theme: 'semanticui',
      });
    });
  },
};

document.addEventListener('colorpicker:init', () => {
  ColorPicker.init();
});

export default ColorPicker;
