import { Controller } from "@hotwired/stimulus";
import { i18n } from "../../src/custom/Internationalization";

export default class extends Controller {
  static targets = [
    "inviteType",
    "private",
    "submitButton",
    "linkMessage",
    "preview",
  ];

  connect = () => {};

  inviteTypeChanged = () => {
    switch (this.inviteTypeTarget.value) {
      case "website":
        this.submitButtonTarget.value = i18n.t(
          "registration.invite.actions.invite_to_website",
        );
        this.linkMessageTarget.innerText = i18n.t(
          "registration.invite.content.event_website_link",
        );
        this.privateTarget.classList.remove("tw-hidden");
        break;
      case "event_registration":
        this.submitButtonTarget.value = i18n.t(
          "registration.invite.actions.invite_to_registration",
        );
        this.linkMessageTarget.innerText = i18n.t(
          "registration.invite.content.event_website_link",
        );
        this.privateTarget.classList.remove("tw-hidden");
        break;
      case "app":
        this.submitButtonTarget.value = i18n.t(
          "registration.invite.actions.invite_to_app",
        );
        this.linkMessageTarget.innerText = i18n.t(
          "registration.invite.content.event_app_link",
        );
        this.privateTarget.classList.add("tw-hidden");
        break;
      default:
        this.submitButtonTarget.value = i18n.t(
          "registration.invite.actions.invite_to_website",
        );
        this.linkMessageTarget.innerText = i18n.t(
          "registration.invite.content.event_website_link",
        );
        this.privateTarget.classList.remove("tw-hidden");
        break;
    }

    const previewUrl = new URL(this.previewTarget.href);
    previewUrl.searchParams.set("invite_type", this.inviteTypeTarget.value);
    this.previewTarget.href = previewUrl.toString();
  };
}
