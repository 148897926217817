import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["attendeeSelector", "frame"];

  changeAttendee = (event) => {
    const newUrl = event.target.value;
    this.frameTarget.src = newUrl;
  };
}
