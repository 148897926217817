const initResourceTypeSwitch = () => {
  document.querySelector('#resource_type').addEventListener('change', (e) => {
    const el = e.currentTarget;
    if (el.value === 'LinkResource') {
      document.querySelector('#document_uploader').setAttribute('hidden', 'hidden');
      document.querySelector('#resource_label').removeAttribute('hidden');
    } else {
      document.querySelector('#resource_label').setAttribute('hidden', 'hidden');
      document.querySelector('#document_uploader').removeAttribute('hidden');
    }
  });
};

const init = () => {
  if (document.querySelector('#resource_form')) {
    initResourceTypeSwitch();
  }
};
document.addEventListener('DOMContentLoaded', init);
