import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["filterForm"];

  show() {
    this.dispatch("show");
  }

  removeFilter(event) {
    if (event.currentTarget.dataset.filterSelector) {
      const elements = this.element.querySelectorAll(
        event.currentTarget.dataset.filterSelector,
      );
      elements.forEach((el) => {
        if (el.tagName === "SELECT") {
          el.value = "";
        } else if (el.tagName === "INPUT") {
          if (el.type === "checkbox" || el.type === "radio") {
            el.checked = false;
          } else {
            el.value = "";
          }
        }
      });
      if (elements.length > 0) {
        this.filterFormTarget.submit();
      }
    }
  }
}
