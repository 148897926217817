const init = () => {
  document.querySelectorAll('.js_toggle_data').forEach((toggleButton) => {
    const tableRowId = toggleButton.getAttribute('data-row_id');
    const accordionRow = document.getElementById(`${tableRowId}_data`) || document.querySelectorAll(`.${tableRowId}_data`);
    const observerOptions = {
      childList: true,
      attributes: true,
      // Omit (or set to false) to observe only changes to the parent node
      subtree: false,
    };

    const toggleClass = (row, add) => {
      const data = row.querySelector('.gr-table-grid__data');
      if (add) {
        row.classList.add('opened');
        data.classList.add('opened');
      } else {
        row.classList.remove('opened');
        data.classList.remove('opened');
      }
    };

    const toggleMutation = (mutationList) => {
      mutationList.forEach((mutation) => {
        const subQuestion = mutation.target.closest('.gr-table-grid__data');
        if (mutation.type === 'attributes' && mutation.target.classList.contains('visible')) {
          subQuestion.style.overflow = 'visible';
        } else {
          subQuestion.style.overflow = 'hidden';
        }
      });
    };

    toggleButton.addEventListener('click', () => {
      const subQuestionMenus = document.querySelectorAll('.gr-table-grid__data .ui.dropdown.gr-button');
      const observer = new MutationObserver(toggleMutation);

      subQuestionMenus.forEach((menuBtn) => {
        observer.observe(menuBtn, observerOptions);
      });

      if (toggleButton.classList.contains('opened')) {
        toggleButton.classList.remove('opened');
        if (!accordionRow.nodeName) {
          accordionRow.forEach((row) => toggleClass(row, false));
        } else {
          toggleClass(accordionRow, false);
        }
      } else {
        toggleButton.classList.add('opened');
        if (!accordionRow.nodeName) {
          accordionRow.forEach((row) => toggleClass(row, true));
        } else {
          toggleClass(accordionRow, true);
        }
      }
    });
  });
};

document.addEventListener('DOMContentLoaded', init);
document.addEventListener('tableaccordion:rebind', init);
