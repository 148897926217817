import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["body"];

  connect = () => {
    const inviteBody = document.querySelector("#invite_body");
    if (inviteBody) {
      this.bodyTarget.innerHTML = inviteBody.value.replace(
        /(\r\n|\n|\r)/gm,
        "</br>",
      );
    }

    const invitePasscode = document.querySelector("#invite_passcode");
    if (invitePasscode && invitePasscode.checked) {
      const passcodeNotice = document.querySelector("#passcode_notice");
      this.bodyTarget.innerHTML += `<br>${passcodeNotice?.innerHTML}`;
    }
  };
}
