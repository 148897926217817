import $ from 'jquery';
import { getCSRFTokenValue } from '../CsrfToken';

const init = () => {
  $('.remove-department')
    .on('click', (e) => {
      const { departmentId } = e.target.dataset;

      $(`#deactivate-department-modal-${departmentId}`)
        .modal({
          onApprove() {
            $.ajax({
              url: `departments/${departmentId}`,
              type: 'DELETE',
              dataType: 'script',
              headers: {
                'X-CSRF-Token': getCSRFTokenValue(),
              },
            });
          },
        })
        .modal('show');
    });
};

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#manage-departments')) {
    init();
  }
});

document.addEventListener('departmentmanagement:init', () => {
  init();
});
