import $ from 'jquery';
import { showModal } from '../Modal';
import { initTableShadow } from '../TableShadow';

export const initEventsDashboardScripts = (() => {
  $('.js-show-filter').on('click', (e) => {
    e.preventDefault();
    showModal('#events_filter', false, { autofocus: true });
  });

  function appendData(name, value) {
    const tempInput = $(`<input name='${name}'>`).attr('type', 'hidden').attr('value', value);
    $('form#multiple-action-form').append($(tempInput));
  }

  function appendDataSubmit(name, value) {
    appendData('page', (window.getUrlParameter('page') || 1));
    appendData('filter', (window.getUrlParameter('filter') || 'upcoming'));
    appendData(name, value);
    $('form#multiple-action-form').submit();
  }

  $('.stage.menu a.item').on('click', (e) => {
    appendDataSubmit('stage', $(e.target).data('stage'));
  });

  $('.sourcing_stage.menu a.item').on('click', (e) => {
    appendDataSubmit('sourcing_stage', $(e.target).data('sourcing-stage'));
  });

  $('.payment_status.menu a.item').on('click', (e) => {
    appendDataSubmit('payment_status', $(e.target).data('payment-status'));
  });

  $('.menu.events_actions__menu a#archive').on('click', () => {
    appendDataSubmit('archive', '1');
  });

  $('.menu.events_actions__menu a#delete').on('confirm:complete', (e, answer) => {
    if (answer) {
      appendDataSubmit('delete', '1');
    }
  });

  initTableShadow('table_view');
});

const init = () => {
  if (document.querySelector('[data-event-dashboard]')) {
    initEventsDashboardScripts();
  }
};

document.addEventListener('DOMContentLoaded', init);
document.addEventListener('eventdashboard:init', init);
