import $ from 'jquery';
import moment from 'moment';

require('jquery-countdown');

export const showCountdownClock = (finalDate) => {
  const targetDate = moment.utc(finalDate, 'YYYY-MM-DD hh:mm:ss zz');
  $('#countdownClock').countdown(targetDate.toDate(), (e) => {
    const $el = $(e.target);
    const days = e.strftime('%D');
    const hrs = e.strftime('%H');
    const mins = e.strftime('%M');
    const secs = e.strftime('%S');

    $el.find('#days').find('.value').html(days);
    $el.find('#hrs').find('.value').html(hrs);
    $el.find('#mins').find('.value').html(mins);
    $el.find('#secs').find('.value').html(secs);
  }).on('finish.countdown', () => {});
};

export const showCountdownLabel = (finalDate, label) => {
  $('#countdownClock')
    .countdown(finalDate, (e) => {
      $(e.target)
        .html(e.strftime(`%Dd %Hh %Mm ${label}`));
    }).on('finish.countdown', () => {});
};

export const showCountdowns = () => {
  document.querySelectorAll('[data-target-date]').forEach((el) => {
    const targetDate = moment.utc(el.dataset.targetDate, 'YYYY-MM-DD hh:mm:ss zz');

    $(el).countdown(targetDate.toDate(), (e) => {
      const $el = $(e.target);
      const days = e.strftime('%D');
      const hrs = e.strftime('%H');
      const mins = e.strftime('%M');
      const secs = e.strftime('%S');

      $el.find('#days').find('.value').html(days);
      $el.find('#hrs').find('.value').html(hrs);
      $el.find('#mins').find('.value').html(mins);
      $el.find('#secs').find('.value').html(secs);
    });
  });
};

const bindCountdownLabel = () => {
  document.querySelectorAll('[data-countdown-finaldate').forEach((el) => {
    if (el.dataset.countdownLabel) {
      showCountdownLabel(el.dataset.countdownFinaldate, el.dataset.countdownLabel);
    }
  });
};

document.addEventListener('DOMContentLoaded', bindCountdownLabel);

window.showCountdownClock = showCountdownClock;
window.showCountdownLabel = showCountdownLabel;
