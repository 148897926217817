export const setConditionalButtonState = (select) => {
  const hideClass = select.dataset.conditionalButtonContainer;
  document.querySelectorAll(hideClass).forEach((container) => {
    if (container.classList.contains(`conditional_${select.value}`)) {
      container.classList.remove("tw-hidden");
    } else {
      container.classList.add("tw-hidden");
    }
  });
};

const selectChanged = (ev) => {
  const select = ev.currentTarget;
  setConditionalButtonState(select);
};

const init = () => {
  document.querySelectorAll(".conditional-button").forEach((select) => {
    setConditionalButtonState(select);
    select.addEventListener("change", selectChanged);
  });
};

document.addEventListener("DOMContentLoaded", init);
