/* eslint-disable func-names */
import $ from 'jquery';
import { initStateSelector } from '../custom/components/StateSelector';
import { getCSRFTokenValue } from '../custom/CsrfToken';
import { getAddressObject } from './GoogleAddressParser';
import { initMap } from './Gmap';

const fillInLatLng = (model, lat, lng, locationName) => {
  const prefix = model ? `${model}_` : '';

  document.getElementById(`${prefix}latitude`).value = lat;
  document.getElementById(`${prefix}longitude`).value = lng;

  const locationNameField = document.getElementById(`${prefix}location_name`);
  if (locationNameField) {
    locationNameField.value = locationName;
  }

  const timeZoneField = document.getElementById(`${prefix}time_zone`);
  if (timeZoneField) {
    $.ajax({
      type: 'GET',
      url: '/api/timezones/show',
      data: {
        lat,
        lng,
      },
      headers: {
        'X-CSRF-Token': getCSRFTokenValue(),
      },
      success(response) {
        if (timeZoneField.nodeName === 'SELECT') {
          $(timeZoneField).dropdown('set selected', response.time_zone);
        } else {
          timeZoneField.value = response.time_zone;
        }
      },
    });
  }
};

const fillInAddress = (model, addressComponents) => {
  const address = getAddressObject(addressComponents);
  const prefix = model ? `${model}_` : '';

  document.getElementById(`${prefix}city`).value = address?.city || '';
  document.getElementById(`${prefix}address_one`).value = address?.addressLine1 || '';
  document.getElementById(`${prefix}address_two`).value = address?.addressLine2 || '';
  document.getElementById(`${prefix}postal_code`).value = address?.postalCode || '';
  document.getElementById(`${prefix}city`).value = address?.city || '';
  if (document.getElementById(`${prefix}country`).tagName === 'SELECT') {
    $(`#${prefix}country`).dropdown('set selected', address.country);
  } else {
    document.getElementById(`${prefix}country`).value = address.country;
  }

  if (document.getElementById(`${prefix}state`).tagName === 'SELECT') {
    initStateSelector(
      $(document.getElementById(`${prefix}state`)),
      address.country,
      (stateOptions) => {
        const stateCode = Object.keys(stateOptions).find((key) => stateOptions[key] === address.stateOrProvince);
        if (stateCode) {
          $(`#${prefix}state`).dropdown('set selected', stateCode);
        } else {
          $(`#${prefix}state`).dropdown('set selected', address.stateOrProvince);
        }
      },
    );
  } else {
    document.getElementById(`${prefix}state`).value = address?.stateOrProvince || '';
  }
};

const registerGooglePlacesAutocomplete = ($el, model) => {
  let gpAutocomplete;
  if (typeof (window.google) !== 'undefined') {
    gpAutocomplete = new window.google.maps.places.Autocomplete($el[0]);
    gpAutocomplete.addListener('place_changed', () => {
      const place = gpAutocomplete.getPlace();

      if (place && place.geometry) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        const locationName = place.formatted_address || $(place.adr_address).text();
        fillInLatLng(model, lat, lng, locationName);
        if ($el[0].hasAttribute('data-location-map-id')) {
          const mapEl = document.getElementById($el[0].dataset.locationMapId);
          mapEl.dataset.lat = lat;
          mapEl.dataset.lng = lng;
          mapEl.title = locationName;
          initMap(mapEl.id);
        }
      }

      if ($el[0].hasAttribute('data-location-set-address')) {
        fillInAddress(model, place.address_components);
      }
      $el.trigger('blur');
    });
    $el.on('blur', () => {
      $el.trigger('change');
    });
    $el.parents('form').on('submit', () => {
      $el.removeAttr('autocomplete');
      return true;
    });
  }
};

export const initLocationAutocomplete = (model) => {
  if (typeof window.google === 'undefined') return;

  $('.location_field').each((ind, el) => {
    let displayModel = model;
    if (el.dataset.locationModel) {
      displayModel = el.dataset.locationModel;
    }
    if (el.dataset.locationMapId) {
      initMap(el.dataset.locationMapId);
    }
    registerGooglePlacesAutocomplete($(el), displayModel);
  });
};

document.addEventListener('locationautocomplete:init', (e) => {
  initLocationAutocomplete(e?.detail?.model);
});
document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.location_field')) {
    initLocationAutocomplete();
  }
});

// Google Maps requires a global callback function
window.locationCallback = initLocationAutocomplete;
