import $ from 'jquery';

const init = () => {
  document.querySelectorAll('[data-detailed-confirm-selector]').forEach((link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      $(link.dataset.detailedConfirmSelector)
        .modal({
          onApprove() {
            const form = document.querySelector(`${link.dataset.detailedConfirmSelector} form`);
            $(form).trigger('submit');
          },
        })
        .modal('show');
    });
  });
};

document.addEventListener('DOMContentLoaded', init);
