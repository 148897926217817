import $ from "jquery";

export const setBulkActionsStatus = ($container) => {
  if (!$container) {
    $(".bulk-actions").toggleClass("disabled", true);
  } else {
    const hasSelectedItems = $container.find(".bulk-item:checked").length > 0;
    $container.find(".bulk-actions").toggleClass("disabled", !hasSelectedItems);
  }
};

const initBatchActions = () => {
  const getBulkIds = ($table) => {
    const itemIds = [];
    $table.find(".bulk-item:checked").each((ind, el) => {
      const dataId = $(el).data("member-id") || $(el).parents("tr").data("id");
      itemIds.push(dataId);
    });
    return itemIds;
  };

  const appendBulkParams = (link) => {
    const $container = $(link).parents(".bulk-container");
    const $table = $container.find(".bulk-table");
    let href = $(link).attr("href");

    const ids = getBulkIds($table);

    href += `?${$(link).data("params")}`;
    ids.forEach((id) => {
      href += `&${$table.data("resource")}[]=${id}`;
    });
    $(link).attr("href", href);
  };

  setBulkActionsStatus(null);

  $(".bulk-item").on("change", (e) => {
    const $container = $(e.target).parents(".bulk-container");
    setBulkActionsStatus($container);
  });

  $(".bulk-actions .bulk-link").on("click", (e) => {
    appendBulkParams(e.target);
  });
};

document.addEventListener("DOMContentLoaded", () => {
  if (
    document.querySelector(".bulk-item") ||
    document.querySelector(".bulk-actions")
  ) {
    initBatchActions();
  }
});

window.init_batch_actions = initBatchActions;

const initMembersActions = (data) => {
  const options = data.map((i) => `${i}`);
  const locked = $("input#locked_ids").val().split(",");

  $(".js_clear_orgs").on("click", () => {
    $(".js_select_orgs").dropdown("set exactly", locked);
  });

  $(".js_add_orgs").on("click", () => {
    $(".js_select_orgs").dropdown("set exactly", options);
  });

  $(".js_select_orgs").dropdown({
    onLabelCreate(value, text) {
      if (locked.includes(value)) {
        $(this).html(`${text}<i class="lock icon"></i>`);
      }

      return $(this);
    },
  });

  $(".js_select_orgs").dropdown("restore defaults");
};
window.initMembersActions = initMembersActions;
