import $ from "jquery";

export const initMultiSelectTable = (callback) => {
  const allSelected = () => $("#all_selected").val() === "1";
  const joinOptions = (selector) => {
    const current = $(selector).val().toString().trim();
    return current === "" ? [] : current.split(",");
  };
  const selectedOpts = () => joinOptions("#selected_ids");
  const unselectedOpts = () => joinOptions("#unselected_ids");

  const setBulkActionsStatus = () => {
    const hasSelectedItems = selectedOpts().length > 0;
    $("#bulk_actions").toggleClass("disabled", !hasSelectedItems);
  };

  const toggleSelected = (elem) => {
    const $parentRow = $(elem).parents("tr");
    const parentRowClass = $parentRow
      .attr("class")
      .split(" ")
      .map((s) => `.${s}`)
      .join("");
    $(parentRowClass).toggleClass("selected");
  };

  const setCheckedCount = () => {
    const $containerElem = $(".selected-record-count-container");
    const $msgElem = $("#selected-record-count-message");

    const selectedCount = selectedOpts().length;
    const unselectedCount = unselectedOpts().length;

    const noun = $msgElem.data("noun");
    const total = $msgElem.data("total");

    let count = selectedCount;

    if (allSelected()) {
      count = total - unselectedCount;
    }

    let message = "";
    if (count === 0) {
      $containerElem.addClass("tw-hidden");
    } else {
      $containerElem.removeClass("tw-hidden");
    }

    message = $msgElem.data("message");
    message = message.replace("{noun}", noun);
    message = message.replace("{count}", count);
    message = message.replace("{total}", total);
    $msgElem.text(message);
  };

  const addToOptions = (elem, selector) => {
    const current = joinOptions(selector);
    if (!current.includes($(elem).val())) {
      current.push($(elem).val());
    }
    $(selector).val(current.join(","));
  };

  const removeFromOptions = (elem, selector) => {
    const current = joinOptions(selector);
    const index = current.indexOf($(elem).val());
    if (index !== -1) {
      current.splice(index, 1);
    }
    $(selector).val(current.join(","));
  };

  const addToSelected = (elem) => {
    addToOptions(elem, "#selected_ids");
  };

  const removeFromSelected = (elem) => {
    removeFromOptions(elem, "#selected_ids");
  };

  const addToUnSelected = (elem) => {
    addToOptions(elem, "#unselected_ids");
  };

  const removeFromUnSelected = (elem) => {
    removeFromOptions(elem, "#unselected_ids");
  };

  const selectItem = (item) => {
    const $elem = $(`.select_record.checkbox input[value=${item}]`);
    $elem.prop("checked", true);
    toggleSelected($elem);
  };

  const setSelectedCheckboxes = () => {
    if (allSelected()) {
      // Check every box on the page unless it has been manually deselected
      $(".select_record.checkbox input").each((ind, el) => {
        const val = $(el).val();
        if (unselectedOpts().indexOf(val) === -1) {
          selectItem(val);
        }
      });
    } else {
      // Check just those options manually chosen
      const current = selectedOpts();

      if (current.length > 0) {
        current.forEach(selectItem);
      }
    }
  };

  const clear = () => {
    $(".select_record.checkbox").each((ind, el) => {
      $(el).checkbox("uncheck");
    });
    $("#select_all").prop("checked", false);
    $("#selected_ids").val("");
    $("#unselected_ids").val("");
    $("#all_selected").val("");
    setCheckedCount();
  };

  $(".select_row").checkbox({
    onChecked() {
      addToSelected(this);
      removeFromUnSelected(this);
      toggleSelected(this);
      setCheckedCount();
      setBulkActionsStatus();
    },
    onUnchecked() {
      removeFromSelected(this);
      addToUnSelected(this);
      toggleSelected(this);
      setCheckedCount();
      setBulkActionsStatus();
      $("#select_all").prop("checked", false);
    },
  });

  $("#selected-record-clear").on("click", () => {
    clear();
  });

  $(".ui.select_all.checkbox").checkbox({
    onChecked() {
      $("#selected_ids").val("");
      $("#unselected_ids").val("");
      const items = $(this).parent().attr("data-items");
      $(`.${items}`).each((ind, el) => {
        $(el).checkbox("check");
      });
      $("#all_selected").val("1");
      setCheckedCount();
    },
    onUnchecked() {
      clear();
    },
  });

  $(".bulk-action").on("click", (e) => {
    $("#multiple-action-form").attr("action", $(e.target).data("submit"));
    $("#multiple-action-form").trigger("submit");
  });

  setBulkActionsStatus();
  setSelectedCheckboxes();
  setCheckedCount();

  if (typeof callback !== "undefined") {
    callback();
  }
};
window.initMultiSelectTable = initMultiSelectTable;
