import $ from "jquery";
import { nanoid } from "nanoid";
import { initializeSemanticUI } from "../../plugins/SemanticUI";
import { initCustomCheckboxes } from "../../plugins/FormFields";
import { initSortable } from "../../plugins/SortableJS";

const initWebsiteSections = (id) => {
  const initToggleScript = () => {
    $(".section_item").on("change", ".js-checkbox-visibility", (e) => {
      e.stopPropagation();
      const parentContainer = $(e.currentTarget)
        .parents(".section_item")
        .first();
      if (parentContainer) {
        parentContainer.toggleClass("grey", !$(e.currentTarget).is(":checked"));
      }
    });
    $(".js-fold")
      .off("click")
      .on("click", (e) => {
        const parentContainer = $(e.currentTarget)
          .parents(".section_item")
          .first();
        if (parentContainer) {
          const contentArea = parentContainer.find(".section_item__body");
          const isOpened = parentContainer.hasClass("opened");
          parentContainer.toggleClass("opened", !isOpened);
          contentArea.slideToggle(isOpened);
        }
      });
  };

  // Initialize Sortable
  // Parameters:
  //  - Container's ID
  //  - Sortable item class
  //  - Input to store it's position
  //  - handler
  //  - callback
  const hasSortableItems = $(".js-sort-item").length > 0;
  if (hasSortableItems) {
    initSortable(
      id,
      ".js-sort-item",
      ".js-sort-item__position",
      ".drag_handler",
      window.updateSortOrder,
    );
  }

  initializeSemanticUI();
  initCustomCheckboxes();

  initToggleScript();

  $(`#${id}`)
    .on("cocoon:after-insert", (e, item) => {
      const newId = nanoid();
      $("#custom__description").prop("id", `custom_${newId}_description`);
      $("#toolbar-custom__description").prop(
        "id",
        `toolbar-custom_${newId}_description`,
      );
      $("#limit_custom__description").prop(
        "id",
        `limit_custom_${newId}_description`,
      );

      initToggleScript();
      document.dispatchEvent(new Event("quill:rebind"));
      window.scrollTo({
        top: $(item).offset().top,
        left: 0,
        behavior: "smooth",
      });
    })
    .on("cocoon:before-remove", (e, item) => {
      $(e.target).data("remove-timeout", 400);
      item.slideUp(300);
    });
};

const init = () => {
  document.querySelectorAll("[data-website-sections]").forEach((el) => {
    initWebsiteSections(el.id);
  });
};
document.addEventListener("DOMContentLoaded", init);
