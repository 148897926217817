import $ from 'jquery';
import { getCSRFTokenValue } from '../CsrfToken';

const initGetOrganizations = (url) => {
  $('#new_account')
    .on('change', () => {
      const accountId = $('#new_account option:selected').val();

      $.ajax({
        url,
        type: 'GET',
        data: {
          account_id: accountId,
        },
        headers: {
          'X-CSRF-Token': getCSRFTokenValue(),
        },
      });
    });

  $('#move_form_submit')
    .on('click', (event) => {
      event.preventDefault();
      const resp = confirm($('#confirmation_text')[0].value);
      if (resp) {
        submitIEForm(event);
      }
    });
};

window.initialize_get_organizations = initGetOrganizations;
