import $ from 'jquery';

const initArchivedToggle = () => {
  $('#show_archived_box').on('change', (e) => {
    $('input#show_archived').val($(e.target).is(':checked'));
    $('#archived_toggle').trigger('submit');
  });
};

document.addEventListener('DOMContentLoaded', () => {
  initArchivedToggle();
});
