import Rails from '@rails/ujs';
import $ from 'jquery';
import toastr from '../../plugins/Toastr';
import { initExporter } from '../exporter';
import { i18n } from '../Internationalization';

const syncObjectIds = () => {
  const objectIdsField = document.querySelector('#cross_event_object_ids');
  const buildObjectIdsField = document.querySelector('#build_object_ids');
  if (objectIdsField && buildObjectIdsField) {
    buildObjectIdsField.value = objectIdsField.value;
  }
};

const addEvent = (id) => {
  const objectIdsField = document.querySelector('#cross_event_object_ids');
  const checkedValues = (objectIdsField.value || '').split(',');

  if (!checkedValues.includes(id)) {
    checkedValues.push(id);
  }

  objectIdsField.value = checkedValues.filter((el) => el !== '').join(',');
  syncObjectIds();
};

const removeEvent = (id) => {
  const objectIdsField = document.querySelector('#cross_event_object_ids');
  const checkedValues = (objectIdsField.value || '').split(',');

  const index = checkedValues.indexOf(id);
  if (index >= 0) {
    checkedValues.splice(index, 1);
    objectIdsField.value = checkedValues.filter((el) => el !== '').join(',');
  }
  const allEventsEl = document.querySelector('#all_events');
  allEventsEl.checked = false;

  const row = document.querySelector('#cross_event_list .events_row_#{id}');
  row?.parentNode?.removeChild(row);

  const allEventsField = document.querySelector('#cross_event_all_events');
  allEventsField.value = 'false';
  syncObjectIds();
};

const listenTableCheck = () => {
  const allEventsEl = document.querySelector('#all_events');
  if (allEventsEl) {
    allEventsEl.addEventListener('change', (e) => {
      const allEventsField = document.querySelector('#cross_event_all_events');
      allEventsField.value = e.currentTarget.checked;
    });
  }

  $('.cross_event_table .checkbox').checkbox({
    onChecked() {
      addEvent(this.value);
    },
    onUnchecked() {
      removeEvent(this.value);
    },
  });
};

const listenInputChange = () => {
  $('.new_name #cross_event_report_name').on('change', (e) => {
    const nameField = document.querySelector('#cross_event_name');
    nameField.value = e.currentTarget.value;
  });
};

const bindRemoveEvents = () => {
  document.querySelectorAll('[data-remove-event]').forEach((el) => {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      removeEvent(el.dataset.removeEvent);
    });
  });
};

const afterDownload = (uuid, data) => {
  $(`#report_download_${uuid} .generating`).replaceWith(
    `<a href="${data.url}" target="_blank" class="tw-mx-4">${i18n.t('actions.download')}</a>`,
  );
  document.querySelector('#download_button').removeAttribute('disabled');
  toastr.clear();
};

const initPreventDoubleClick = () => {
  document.querySelector('#download_button').addEventListener('click', (e) => {
    e.preventDefault();
    if (!e.detail || e.detail === 1) {
      e.target.setAttribute('disabled', 'disabled');
      const form = document.querySelector('#download_report');
      Rails.fire(form, 'submit');
    }
    return false;
  });
};

const setSelectedValues = () => {
  const allEventsEl = document.querySelector('#all_events');
  const allEventsField = document.querySelector('#cross_event_all_events');
  const objectIdsField = document.querySelector('#cross_event_object_ids');

  if (allEventsField.value === 'true') {
    document.querySelectorAll('.select-event-cb').forEach((el) => {
      el.checked = true;
    });
  } else {
    const checkedValues = (objectIdsField.value || '').split(',').filter((el) => el !== '');
    checkedValues.forEach((id) => {
      const checkbox = document.querySelector(`[type='checkbox'][value='${id}']`);
      if (checkbox) {
        checkbox.checked = true;
      }
    });
  }

  if (allEventsEl) {
    allEventsEl.checked = allEventsField.value === 'true';
  }

  const newNameField = document.querySelector('#cross_event_report_name');
  const hiddenNameField = document.querySelector('#cross_event_name');
  if (hiddenNameField && newNameField) {
    newNameField.value = hiddenNameField.value;
  }
};

const resetCrossEventsScripts = () => {
  listenTableCheck();
  bindRemoveEvents();
  setSelectedValues();
  listenInputChange();
};

const initCrossEventsScripts = () => {
  if (document.querySelector('[data-cross-event-reporting]')) {
    initExporter(afterDownload, '.cross_event_report_export');
    initPreventDoubleClick();
    resetCrossEventsScripts();
    document.dispatchEvent(new Event('eventdashboard:init'));
  }
};

document.addEventListener('DOMContentLoaded', initCrossEventsScripts);
document.addEventListener('crossevent:reset', resetCrossEventsScripts);
