import $ from 'jquery';

const init = (item) => {
  $(item).each((_, el) => {
    const $item = $(el);

    $item.off('click').on('click', (e) => {
      const index = $(e.currentTarget).data('scrollto');
      const $containerPosition = $(`#${index}`).position();

      if ($containerPosition) {
        window.scrollTo({
          top: $containerPosition.top,
          behavior: 'smooth',
        });
      }
    });
  });
};

document.addEventListener('listscrollto:init', (e) => {
  init(e?.detail?.selector);
});
