import $ from 'jquery';

export const initMoreLessScript = () => {
  $('.js-section-more').on('click', (e) => {
    e.preventDefault();
    const parent = $(e.target).parents('.page_section');
    parent.addClass('detailed');
  });

  $('.js-section-less').on('click', (e) => {
    e.preventDefault();
    const parent = $(e.target).parents('.page_section');
    parent.removeClass('detailed');
  });
};

const init = () => {
  if (document.querySelector('.js-section-more')) {
    initMoreLessScript();
  }
};

document.addEventListener('DOMContentLoaded', init);
