import Clipboard from 'clipboard';
import $ from 'jquery';

const initClipboardBtn = (selector) => {
  const btnElement = selector || '.clipboard-btn';
  const clipboard = new Clipboard(btnElement);

  const setTooltip = (btn, message) => {
    $(btn).popup({
      position: 'bottom center',
      preserve: true,
      lastResort: true,
      transition: 'fade down',
      content: message,
      target: btn,
      on: 'click',
    }).popup('show');
  };

  const hideTooltip = (btn) => {
    setTimeout(() => {
      $(btn).popup('hide');
    }, 1000);
  };

  clipboard.on('success', (e) => {
    setTooltip(e.trigger, 'Copied!');
    hideTooltip(e.trigger);
  });

  clipboard.on('error', (e) => {
    setTooltip(e.trigger, 'Failed!');
    hideTooltip(e.trigger);
  });
};
document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('[data-clipboard-action]')) {
    initClipboardBtn('[data-clipboard-action]');
  }
});

document.addEventListener('clipboard:init', (e) => {
  initClipboardBtn(e.selector);
});

export default initClipboardBtn;
