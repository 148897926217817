import $ from 'jquery';
import { initSortable } from '../../plugins/SortableJS';
import { showModal } from '../Modal';
import toastr from '../../plugins/Toastr';

const checkDisplayWithMandatoryFields = () => {
  const $mandatoryField = $('#reg_form_update_container input[type="checkbox"][name$="[required]"]');
  const $displayField = $('#reg_form_update_container input[type="checkbox"][name$="[active]"]');
  const $enableField = $('#reg_form_update_container input[type="checkbox"][name$="[enabled]"]');

  $mandatoryField.on('click', (e) => {
    if ($(e.target).is(':checked')) {
      const $row = $(e.target).closest('.gr-table-row');
      const displayField = $row.find('input[type="checkbox"][name$="[active]"]');
      const enabledField = $row.find('input[type="checkbox"][name$="[enabled]"]');
      displayField.prop('checked', true);
      enabledField.prop('checked', true);
    } else if ($(e.target).data('mandatory-field-name') === 'email') {
      toastr.warning(
        i18n.t('registration.notices.disable_email_warning'),
        '',
        {
          timeOut: 0,
          iconClass: 'toast-warning',
        },
      );
    }
  });

  $displayField.on('click', (e) => {
    const $row = $(e.target).closest('.gr-table-row');
    if ($(e.target).is(':checked')) {
      const enabledField = $row.find('input[type="checkbox"][name$="[enabled]"]');
      enabledField.prop('checked', true);
    } else {
      const requiredField = $row.find('input[type="checkbox"][name$="[required]"]');
      requiredField.prop('checked', false);
      if ($(e.target)
        .data('mandatory-field-name') === 'email') {
        toastr.warning(
          i18n.t('registration.notices.disable_email_warning'),
          '',
          {
            timeOut: 0,
            iconClass: 'toast-warning',
          },
        );
      }
    }
  });

  $enableField.on('click', (e) => {
    if (!$(e.target).is(':checked')) {
      const $row = $(e.target).closest('.gr-table-row');
      const requiredField = $row.find('input[type="checkbox"][name$="[required]"]');
      requiredField.prop('checked', false);
      const displayField = $row.find('input[type="checkbox"][name$="[active]"]');
      displayField.prop('checked', false);
      if ($(e.target).data('mandatory-field-name') === 'email') {
        toastr.warning(
          i18n.t('registration.notices.disable_email_warning'),
          '',
          {
            timeOut: 0,
            iconClass: 'toast-warning',
          },
        );
      }
    }
  });
};

const initRegistrationCheckboxes = (container) => {
  const checkStates = () => {
    const $container = $(container);
    const $toggleAll = $container.find('.js_toggle_all').first();
    const $toggleItems = $container.find('.js_toggle_item');

    const amountItems = $toggleItems.length;
    const amountCheckedItems = $container.find('.js_toggle_item:checked').length;
    if (amountItems === amountCheckedItems) {
      $toggleAll.prop('checked', true).attr('checked', 'checked');
      $toggleAll.toggleClass('semi', false);
    } else {
      $toggleAll.prop('checked', false).removeAttr('checked');
      const isAnyChecked = amountCheckedItems > 0;
      $toggleAll.toggleClass('semi', isAnyChecked);
    }
  };

  const initEvents = () => {
    const $container = $(container);
    const $toggleAll = $container.find('.js_toggle_all').first();
    const $toggleItems = $container.find('.js_toggle_item');
    $toggleAll.on('change', (e) => {
      const $el = $(e.target);
      const state = $el.is(':checked');
      $toggleItems.each((i, el) => {
        if (state) {
          $(el).prop('checked', true).attr('checked', 'checked');
        } else {
          $(el).prop('checked', false).removeAttr('checked');
        }
      });
      checkStates();
    });

    $toggleItems.each((i, el) => {
      $(el).on('change', () => {
        checkStates();
      });
    });
  };

  initEvents();
  checkStates();
};
window.initRegistrationCheckboxes = initRegistrationCheckboxes;

const initRegFieldsScript = (id) => {
  // Initialize Sortable
  // Parameters:
  //  - Container's ID
  //  - Sortable item class
  //  - Input to store it's position
  //  - handler
  //  - callback
  const hasSortableItems = $('.js-sort-item').length > 0;
  if (hasSortableItems) {
    initSortable(
      id,
      '.js-sort-item',
      '.js-sort-item__position',
      '.js-sort-item',
      window.updateSortOrder,
    );
  }

  checkDisplayWithMandatoryFields();
  initRegistrationCheckboxes('#reg-fields-search-form');
};
window.initRegFieldsScript = initRegFieldsScript;
