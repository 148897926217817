import $ from 'jquery';
import { initAllFlatpickrMulti } from '../DatetimeInit';
import { getCSRFTokenValue } from '../CsrfToken';
import { bindAddedAttendeeTypeContainer } from '../components/AttendeeTypesSelector';
import { setUniqIds } from '../InitApp';
import { randomizeAccordionSelector } from '../components/Accordion';

const initRoomTypeFields = () => {
  $('#room_types')
    .on('cocoon:after-insert', (e, addedRoom) => {
      randomizeAccordionSelector(addedRoom[0], 'room_night_breakdown_0');
      setUniqIds('room_description');
      document.dispatchEvent(new Event('quill:rebind'));
      initAllFlatpickrMulti();
    })
    .on('cocoon:before-remove', (e, item) => {
      $(e.target).data('remove-timeout', 1000);
      item.fadeOut('slow');
    });
};

const initRoomBlockEvents = () => {
  $('#reservation_detail_date_option_peak').on('click', () => {
    $('#peak-dates').show();
  });

  $('#reservation_detail_date_option_flexible').on('click', () => {
    $('#peak-dates').hide();
  });
  $('#reservation_detail_maximum_stay_switch').on('click', () => {
    $('#maximum_stay_field').toggle();
  });
};

export const initializePreValidationForm = (hideParent = true) => {
  $('.pre-validation-confirmation .button.cancel').on('click', () => {
    if (hideParent) {
      $('.pre-validation').modal('hide');
    }

    $('.pre-validation-confirmation').modal('hide');
  });

  $('.pre-validation-confirmation .button.confirm').on('click', () => {
    $('.pre-validation').data('validate', false).trigger('submit');
  });
};

export const validateAndSubmit = (e, type) => {
  e.preventDefault();
  e.stopPropagation();
  const formId = $(e.target).attr('form');
  const form = $(`#${formId}`);
  if (form.data('validate')) {
    const validationPath = form.data('validation-path');

    let url;
    let data;

    if (type === 'GET') {
      url = `${validationPath}?${form.serialize()}`;
      data = null;
    } else {
      url = validationPath;
      data = form.serialize();
    }

    $.ajax({
      url,
      type,
      dataType: 'JSON',
      data,
      headers: {
        'X-CSRF-Token': getCSRFTokenValue(),
      },
      success(resp) {
        if (!resp.valid) {
          $('.pre-validation-confirmation').modal('show');
        } else {
          form.data('validate', false).trigger('submit');
        }
      },
    });
  }
};

const setupRoomBlockValidation = () => {
  const $btn = $('#save_btn');
  if ($btn.length === 0) return;

  initializePreValidationForm(false);

  $btn.attr('form', 'rooming_list_form');
  $btn.removeClass('hide');
  $btn[0].onclick = (e) => validateAndSubmit(e, 'PUT');
};

const initRoomTypeAttendeeTypes = () => {
  $('#room_types')
    .on('cocoon:after-insert', (e, item) => {
      const attendeeTypeContainer = item[0].querySelector('.attendee-type-selector');
      if (attendeeTypeContainer) {
        bindAddedAttendeeTypeContainer(attendeeTypeContainer);
      }
    });
};

const init = () => {
  if (document.querySelector('[data-rooming-list]')) {
    initRoomTypeFields();
    initRoomBlockEvents();
    setupRoomBlockValidation();
    initAllFlatpickrMulti();
    initRoomTypeAttendeeTypes();
  }
};

document.addEventListener('DOMContentLoaded', init);
