import $ from "jquery";
import { initializeSemanticUI } from "../plugins/SemanticUI";
import initClipboardBtn from "../plugins/Clipboard";
import { initExporter, setExportDownloadLink } from "./exporter";

export const InitDashboardWebsiteContentScripts = () => {
  $(".remove_video").on("click", (e) => {
    e.preventDefault();
    $("#website_cover_video_url").val("");
    $(e.target).parents(".segment").find(".video_file").slideUp(300);
  });
  initClipboardBtn(".clipboard");
  initializeSemanticUI();
  if (document.querySelector(".all-data-link")) {
    initExporter(setExportDownloadLink, ".all-data-link");
  }
};
