const initCloneEvent = () => {
  const allCheckbox = document.querySelector('#clone_all');
  const optionCheckboxes = document.querySelectorAll('.clone-option');
  allCheckbox.addEventListener('change', () => {
    if (allCheckbox.checked) {
      optionCheckboxes.forEach((option) => {
        option.checked = true;
      });
    } else {
      optionCheckboxes.forEach((option) => {
        option.checked = false;
      });
    };
  });

  optionCheckboxes.forEach((option) => { 
    option.addEventListener('change', () => {
      if (!(option.checked)) {
        allCheckbox.checked = false;
      }
    });
  });
};

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#clone_option_container')) {
    initCloneEvent();
  }
});

document.addEventListener('cloneall:init', () => {
  initCloneEvent();
});