import { initMultiSelectTable } from '../multi_select_table';
import { initializeSemanticUI } from '../../plugins/SemanticUI';

const init = () => {
  if (document.querySelector('[data-attendee-profile-list]')) {
    initMultiSelectTable();
    initializeSemanticUI();
  }
};

document.addEventListener('DOMContentLoaded', init);
