import Rails from "@rails/ujs";
import $ from "jquery";

export const initCustomCheckboxes = () => {
  document
    .querySelectorAll(
      ".toggle.checkbox input[type=checkbox], .autosubmit.checkbox input[type=checkbox]",
    )
    .forEach((elem) => {
      if (!elem.classList.contains("no-submit")) {
        elem.addEventListener("change", (e) => {
          const form = e.target.closest("form");
          Rails.fire(form, "submit");
        });
      }
    });

  const setConditionallyRequired = (input) => {
    const targetOptionalLabels = input.attr("data-optional");

    if (input.is(":checked")) {
      $(`.${targetOptionalLabels}`).each((ind, el) => {
        $(el).text($(el).data("optional-text"));
      });
    } else {
      $(`.${targetOptionalLabels}`).each((ind, el) => {
        $(el).text($(el).data("required-text"));
      });
    }
  };

  const initCustomCheckbox = (element) => {
    const disableField = (field, show, $mainInput) => {
      $(field).each((ind, el) => {
        const $currentInputWrapper = $(el);
        const $eventWrapper = $currentInputWrapper.find(".checkbox--disable");
        const $currentInput = $currentInputWrapper.find("input");
        const currentInputState = $currentInput.prop("checked");
        $currentInputWrapper
          .find(".ui.checkbox")
          .toggleClass("disabled", !show);
        $currentInputWrapper.find(".ui.input").toggleClass("disabled", !show);
        $currentInputWrapper.find(".ui.button").toggleClass("disabled", !show);
        $currentInputWrapper
          .find(".ui.dropdown")
          .toggleClass("disabled", !show);
        $currentInput.prop("disabled", !show);

        if (
          $currentInput.data("disabled-value") &&
          $currentInput.data("disabled-value") !== "undefined" &&
          !show
        ) {
          $currentInput.val($currentInput.data("disabled-value"));
        }

        if ($eventWrapper) {
          $eventWrapper.toggleClass("show", !show);
          $eventWrapper.unbind("click").bind("click", () => {
            if (!show) {
              $mainInput.prop("checked", true).trigger("change");
              $currentInput.prop("checked", !currentInputState);
            }
          });
        }
      });
    };

    const $input = element;

    const targetFieldToDisable = $input.attr("data-disable-field");
    if (targetFieldToDisable) {
      const currentInitState = $input.prop("checked");
      disableField(`.${targetFieldToDisable}`, currentInitState, $input);

      $input.bind("change", (e) => {
        const currentFieldState = $(e.target).prop("checked");
        disableField(`.${targetFieldToDisable}`, currentFieldState, $input);
      });
    }

    const targetFieldToEnable = $input.attr("data-enable-field");
    if (targetFieldToEnable) {
      const currentInitState = $input.prop("checked");
      disableField(`.${targetFieldToEnable}`, !currentInitState, $input);

      $input.bind("change", (e) => {
        const currentFieldState = $(e.target).prop("checked");
        disableField(`.${targetFieldToEnable}`, !currentFieldState, $input);
      });
    }

    const hideEffect = $input.attr("data-effect");
    const targetFieldToHide = $input.attr("data-hide-field");
    if (targetFieldToHide) {
      if ($input.prop("checked")) {
        $(`.${targetFieldToHide}`).show();
        $(`.${targetFieldToHide}`)
          .find("input")
          .each((ind, el) => {
            $(el).prop("disabled", false);
          });
      } else {
        $(`.${targetFieldToHide}`).hide();
        $(`.${targetFieldToHide}`)
          .find("input")
          .each((ind, el) => {
            $(el).prop("disabled", true);
          });
      }

      $input.bind("change", (e) => {
        if ($(e.target).prop("checked")) {
          if (hideEffect === "fade") {
            $(`.${targetFieldToHide}`).fadeIn();
          } else {
            $(`.${targetFieldToHide}`).slideDown(300);
          }

          $(`.${targetFieldToHide}`)
            .find("input")
            .each((indx, elem) => {
              $(elem).prop("disabled", false);
            });
        } else {
          if (hideEffect === "fade") {
            $(`.${targetFieldToHide}`).fadeOut();
          } else {
            $(`.${targetFieldToHide}`).slideUp();
          }

          $(`.${targetFieldToHide}`)
            .find("input")
            .each((indx, elem) => {
              $(elem).prop("disabled", true);
            });
        }
      });
    }

    const targetFieldToShow = $input.attr("data-show-field");
    if (targetFieldToShow) {
      if (!$input.prop("checked")) {
        $(`.${targetFieldToShow}`).show();
        $(`.${targetFieldToShow}`)
          .find("input")
          .each((ind, el) => {
            $(el).prop("disabled", false);
          });
      } else {
        $(`.${targetFieldToShow}`).hide();
        $(`.${targetFieldToShow}`)
          .find("input")
          .each((indx, elem) => {
            $(elem).prop("disabled", true);
          });
      }

      $input.bind("change", (e) => {
        if (!$(e.target).prop("checked")) {
          if (hideEffect === "fade") {
            $(`.${targetFieldToShow}`).fadeIn();
          } else {
            $(`.${targetFieldToShow}`).slideDown(300);
          }

          $(`.${targetFieldToShow}`)
            .find("input")
            .each((indx, elem) => {
              $(elem).prop("disabled", false);
            });
        } else {
          if (hideEffect === "fade") {
            $(`.${targetFieldToShow}`).fadeOut();
          } else {
            $(`.${targetFieldToShow}`).slideUp();
          }

          $(`.${targetFieldToShow}`)
            .find("input")
            .each((indx, elem) => {
              $(elem).prop("disabled", true);
            });
        }
      });
    }

    const targetOptionalLabels = $input.attr("data-optional");
    if (targetOptionalLabels) {
      // If this exists
      // And the input is checked
      // Mark all conditionally-required as optional
      // else
      // Mark all conditionally-required as required
      if (!$input.prop("checked")) {
        $(`.${targetFieldToShow} label.conditionally-required`);
        $(`.${targetFieldToShow} label.conditionally-required`).text();
        $(`.${targetFieldToShow}`)
          .find("input")
          .each((ind, el) => {
            $(el).prop("disabled", false);
          });
      } else {
        $(`.${targetFieldToShow}`).hide();
        $(`.${targetFieldToShow}`)
          .find("input")
          .each((ind, el) => {
            $(el).prop("disabled", true);
          });
      }

      $input.bind("change", (e) => {
        if (!$(e.target).prop("checked")) {
          if (hideEffect === "fade") {
            $(`.${targetFieldToShow}`).fadeIn();
          } else {
            $(`.${targetFieldToShow}`).slideDown(300);
          }

          $(`.${targetFieldToShow}`)
            .find("input")
            .each((indx, elem) => {
              $(elem).prop("disabled", false);
            });
        } else {
          if (hideEffect === "fade") {
            $(`.${targetFieldToShow}`).fadeOut();
          } else {
            $(`.${targetFieldToShow}`).slideUp();
          }

          $(`.${targetFieldToShow}`)
            .find("input")
            .each((indx, elem) => {
              $(elem).prop("disabled", true);
            });
        }
      });
    }

    if ($input.attr("data-optional")) {
      setConditionallyRequired($input);
      $input.bind("change", () => {
        setConditionallyRequired($input);
      });
    }

    $(".ui.sticky").sticky("refresh");
  };

  const initCustomRadiobox = (element) => {
    const $input = element;

    const targetFieldToHide = $input.attr("data-hide-field");
    const targetFieldToShow = $input.attr("data-show-field");

    const targetFieldToDisable = $input.attr("data-disable-field");
    const targetFieldToEnable = $input.attr("data-enable-field");

    const targetFieldToCheck = $input.attr("data-set-checked");

    if ($input.prop("checked")) {
      if (targetFieldToShow) {
        $(`.${targetFieldToShow}`).show();
      }

      if (targetFieldToHide) {
        $(`.${targetFieldToHide}`).hide();
      }

      if (targetFieldToDisable) {
        $(`.${targetFieldToDisable}`).addClass("disabled");
        $(`.${targetFieldToDisable}`).prop("disabled", true);
      }

      if (targetFieldToEnable) {
        $(`.${targetFieldToDisable}`).removeClass("disabled");
        $(`.${targetFieldToDisable}`).prop("disabled", false);
      }

      if (targetFieldToCheck) {
        $(`.${targetFieldToCheck}`).prop("checked", true);
      }
    }

    $input.bind("change", (e) => {
      const disableClearTarget = $(e.currentTarget).data("disable-field-clear");
      if (disableClearTarget) {
        $(`.${disableClearTarget}`).removeClass("disabled");
        $(`.${disableClearTarget}`).prop("disabled", false);
      }

      const enableClearTarget = $(e.currentTarget).data("enable-field-clear");
      if (enableClearTarget) {
        $(`.${enableClearTarget}`).addClass("disabled");
        $(`.${enableClearTarget}`).prop("disabled", true);
      }

      if ($(e.target).prop("checked")) {
        if (targetFieldToShow) {
          if ($(`.${targetFieldToShow}`).is(":hidden")) {
            $(`.${targetFieldToShow}`).slideDown(300);
          }
        }

        if (targetFieldToHide) {
          if ($(`.${targetFieldToHide}`).is(":visible")) {
            $(`.${targetFieldToHide}`).slideUp(300);
          }
        }

        if (targetFieldToDisable) {
          $(`.${targetFieldToDisable}`).addClass("disabled");
          $(`.${targetFieldToDisable}`).prop("disabled", true);
        }

        if (targetFieldToEnable) {
          $(`.${targetFieldToEnable}`).removeClass("disabled");
          $(`.${targetFieldToEnable}`).prop("disabled", false);
        }

        if (targetFieldToCheck) {
          $(`.${targetFieldToCheck}`).prop("checked", true);
        }
      }
    });

    $(".ui.sticky").sticky("refresh");
  };

  $(
    ".conditional.checkbox input[type=checkbox], input[type=radio].conditional",
  ).each((ind, el) => {
    initCustomCheckbox($(el));
  });

  $(
    ".conditional.checkbox input[type=radio], input[type=radio].conditional",
  ).each((_, el) => {
    initCustomRadiobox($(el));
  });

  $(".select_all").checkbox({
    onChecked() {
      const items = $(this).parent().attr("data-items");
      $(`.${items}`).each((ind, el) => {
        $(el).checkbox("check");
      });
      $("#selected_count").removeClass("hide");
    },
    onUnchecked() {
      const items = $(this).parent().attr("data-items");
      $(`.${items}`).each((ind, el) => {
        $(el).checkbox("uncheck");
      });
      $("#selected_count").addClass("hide");
    },
  });
};
window.initCustomCheckboxes = initCustomCheckboxes;

// Selects
export const initCustomSelects = () => {
  $(".conditional.select select").each((ind, el) => {
    const $input = $(el);
    const targetFieldToToggle = $input.attr("data-conditional-field");

    if (targetFieldToToggle) {
      if ($input.val() === "true") {
        $(`.${targetFieldToToggle}_false`).hide();
        $(`.${targetFieldToToggle}_true`).show();
      } else {
        $(`.${targetFieldToToggle}_true`).hide();
        $(`.${targetFieldToToggle}_false`).show();
      }

      $input.on("change", () => {
        if ($input.val() === "true") {
          $(`.${targetFieldToToggle}_true`).slideDown();
          $(`.${targetFieldToToggle}_false`).slideUp();
        } else {
          $(`.${targetFieldToToggle}_false`).slideDown();
          $(`.${targetFieldToToggle}_true`).slideUp();
        }
      });
    }
  });
};
window.initCustomSelects = initCustomSelects;

// Range Input
export const initRangeInput = () => {
  $("input[type=range].range").each((ind, el) => {
    const $labelEl = $(el).parent().find("label");
    $(el)
      .on("input", (e) => {
        $(e.target).trigger("change");
      })
      .on("change", (e) => {
        const value = $(e.target).val();
        $labelEl.find("span").text(value);
      });
  });
};
