import $ from 'jquery';
import { toggleContent } from '../Utils';

const initEventSpeaker = () => {
  $('#speaker_search_text').keyup((e) => {
    const term = e.target.value.toLowerCase();
    const speakers = document.getElementsByClassName('speaker_name');
    Array.from(speakers).forEach((speaker) => {
      const title = speaker.textContent.trim();
      if (title.toLowerCase().indexOf(term) !== -1) {
        speaker.parentElement.style.display = 'flex';
      } else {
        speaker.parentElement.style.display = 'none';
      }
    });
  });
};

const initEventTrack = () => {
  $('#track_search_text').keyup((e) => {
    const term = e.target.value.toLowerCase();
    const tracks = document.getElementsByClassName('track_name');
    Array.from(tracks).forEach((track) => {
      const title = track.textContent.trim();
      if (title.toLowerCase().indexOf(term) !== -1) {
        track.parentElement.style.display = 'flex';
      } else {
        track.parentElement.style.display = 'none';
      }
    });
  });
};

const initEventSponsor = () => {
  $('#sponsor_search_text').keyup((e) => {
    const term = e.target.value.toLowerCase();
    const sponsors = document.getElementsByClassName('sponsor_name');
    Array.from(sponsors).forEach((sponsor) => {
      const title = sponsor.textContent.trim();
      if (title.toLowerCase().indexOf(term) !== -1) {
        sponsor.parentElement.style.display = 'flex';
      } else {
        sponsor.parentElement.style.display = 'none';
      }
    });
  });
};

const initSessionEditing = () => {
  document.dispatchEvent(new Event('documentmanagement:init'));
  document.dispatchEvent(new Event('clipboard:init'));

  const form = document.querySelector('.tw-container #event_session_form');
  const checkboxes = form.querySelectorAll('input[name^="event_session"][type="checkbox"]');
  const toggleContainer = form.querySelector('[data-toggle-check="bookable"]');
  const cleanCheckboxName = (name) => name.replace(/(event_session)|[\][]/g, '');
  const checkboxesBookable = Array.from(checkboxes).filter((box) => cleanCheckboxName(box.name) === 'website_bookable'
    || cleanCheckboxName(box.name) === 'app_bookable');
  const bookableOptions = Array.from(checkboxes).filter((box) => cleanCheckboxName(box.name) === 'included'
  || cleanCheckboxName(box.name) === 'enable_waitlist');
  const isBookable = (fields) => fields.map((field) => field.checked === true).some((v) => v === true);

  const toggleDisabled = (elem, disabledValue) => {
    elem.checked = false;
    elem.disabled = disabledValue;
    if (disabledValue) {
      elem.classList.add('disabled');
    } else {
      elem.classList.remove('disabled');
    }
  };

  const clearFields = (container) => {
    container.querySelectorAll('input').forEach((field) => {
      if (field.type === 'checkbox') {
        field.checked = false;
        toggleDisabled(field, false);
      } else {
        field.value = '';
      }
    });
  };

  checkboxes.forEach((cb) => {
    cb.addEventListener('change', (e) => {
      const box = e.target;
      const name = cleanCheckboxName(box.name);
      if (name === 'website_bookable' || name === 'app_bookable') {
        toggleContent(toggleContainer, isBookable(checkboxesBookable));
        if (!isBookable(checkboxesBookable)) { clearFields(toggleContainer); }
      }
      if (name === 'included' || name === 'enable_waitlist') {
        const elemToToggle = bookableOptions.find((option) => cleanCheckboxName(option.name) !== name);
        toggleDisabled(elemToToggle, box.checked);
      }
    });
  });
};

const init = () => {
  if (document.querySelector('#new_event_speaker')) {
    initEventSpeaker();
    initEventTrack();
    initEventSponsor();
  }

  if (document.querySelector('[data-session-management]')) {
    initSessionEditing();
  }
};

document.addEventListener('DOMContentLoaded', init);
