const init = () => {
  const userpilotElem = document.querySelector('[data-userpilot-data]');

  if (userpilotElem) {
    if (userpilotElem.dataset.userpilotData) {
      const decoded = atob(userpilotElem.dataset.userpilotData);
      const obj = JSON.parse(decoded);
      if (obj.id) {
        window.userpilot.identify(obj.id, obj);
      }
    }
  }
};

document.addEventListener('DOMContentLoaded', init);
