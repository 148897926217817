import $ from "jquery";

document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector("#gds-remarks")) {
    $(".override-remark-button, .ignore-remark-button")
      .data("association-insertion-method", "after")
      .data("association-insertion-node", (link) =>
        link.closest(".section-panel"),
      );

    $("#general_remarks, #accounting_remarks").on(
      "cocoon:before-insert",
      (event) => {
        $(event.target).hide();
      },
    );

    $("#accounting_remarks").on(
      "cocoon:before-remove",
      (event, removedItem) => {
        const ignore = $(removedItem).data("ignore");
        const override = $(removedItem).data("override");
        const id = $(removedItem).data("parentid");

        if (ignore || override) {
          $(`#accounting-remark-${id}`).show();
        }
      },
    );

    $("#general_remarks").on("cocoon:before-remove", (event, removedItem) => {
      const ignore = $(removedItem).data("ignore");
      const override = $(removedItem).data("override");
      const id = $(removedItem).data("parentid");

      if (ignore || override) {
        $(`#general-remark-${id}`).show();
      }
    });
  }

  if (document.querySelector("[data-validated-gds-remarks]")) {
    $("#general_remarks, #accounting_remarks").on("cocoon:after-insert", () => {
      document.dispatchEvent(new Event("rebind-validation"));
    });

    $("#general_remarks, #accounting_remarks").on("cocoon:after-remove", () => {
      document.dispatchEvent(new Event("rebind-validation"));
    });
  }

  if (document.querySelector("[data-validated-gds-remarks]")) {
    $("#reservation_detail_hotel_booking_mode_registration").on("click", (e) => {
      $("[data-hotel-booking-instant-mandatory]").removeClass("hide");
    });
    $("#reservation_detail_hotel_booking_mode_ebp").on("click", (e) => {
      $("[data-hotel-booking-instant-mandatory]").addClass("hide");
    });
  }

});
