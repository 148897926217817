import $ from "jquery";
import "../../plugins/CleaveCard";

import { initSplide } from "../../plugins/Splide";
import { initCustomCheckboxes } from "../../plugins/FormFields";
import { getCSRFTokenValue } from "../CsrfToken";
import { initGroupizeTerms } from "../registration/CreateForm";

const fillPrimaryGuestFields = (block, index) => {
  const firstName = $("#proposal_request_planner_first_name").val();
  const lastName = $("#proposal_request_planner_last_name").val();
  const email = $("#proposal_request_planner_email").val();
  const phone = $("#proposal_request_billing_phone").val();

  $(block)
    .find(
      `#proposal_request_proposal_request_rooms_attributes_${index}_owner_first_name`,
    )
    .val(firstName);
  $(block)
    .find(
      `#proposal_request_proposal_request_rooms_attributes_${index}_owner_last_name`,
    )
    .val(lastName);
  $(block)
    .find(
      `#proposal_request_proposal_request_rooms_attributes_${index}_owner_email`,
    )
    .val(email);
  $(block)
    .find(
      `#proposal_request_proposal_request_rooms_attributes_${index}_registration_attributes_phone`,
    )
    .val(phone);
  $(block)
    .find(
      `#proposal_request_proposal_request_rooms_attributes_${index}_owner_phone`,
    )
    .val(phone);
};

const allowCopyPrimaryGuest = () => {
  $(".copy_checkbox").each((ind, el) => {
    $(el).on("change", () => {
      if (el.checked) {
        const index = $(el).data("index");
        fillPrimaryGuestFields(document, index);
      }
    });
  });
};

export const initCompanionFields = () => {
  $("#companions_container")
    .on("cocoon:after-insert", (e, insertedItem) => {
      insertedItem[0]
        .querySelectorAll("[data-form-validate]")
        .forEach((field) => {
          if (field.dataset.formValidateErrorsContainer) {
            const newErrorContainer = `${field.id}_container`;
            const originalErrorContainer = insertedItem[0].querySelector(
              field.dataset.formValidateErrorsContainer,
            );
            originalErrorContainer.id = newErrorContainer;
            field.dataset.formValidateErrorsContainer = `#${newErrorContainer}`;
          }
        });
      document.dispatchEvent(new Event("rebind-validation"));
    })
    .on("cocoon:after-remove", () => {
      document.dispatchEvent(new Event("rebind-validation"));
    });
};

export const initProposalRequestFields = () => {
  initCustomCheckboxes();
  initGroupizeTerms();

  initSplide(".splide");

  initCompanionFields();
  allowCopyPrimaryGuest();
};

export const initProposalRequestRoomFields = (id, maxGuests) => {
  const block = `#${id}`;
  // eslint-disable-next-line no-param-reassign
  maxGuests = parseInt(maxGuests, 10) || 4;
  $(block)
    .on("cocoon:after-insert", (e, insertedItem) => {
      if ($(block).find(".nested-fields").length >= maxGuests - 1) {
        $(block).find(".add_guest_action").addClass("hide");
      }
      let counter = $(block).find(".nested-fields").length;
      $(block)
        .find(".nested-fields")
        .each((ind, el) => {
          $(el).attr("id", `guest-${counter}`);
          counter -= 1;
        });
      insertedItem[0]
        .querySelectorAll("[data-form-validate]")
        .forEach((field) => {
          if (field.dataset.formValidateErrorsContainer) {
            const newErrorContainer = `${field.id}_container`;
            const originalErrorContainer = insertedItem[0].querySelector(
              field.dataset.formValidateErrorsContainer,
            );
            originalErrorContainer.id = newErrorContainer;
            field.dataset.formValidateErrorsContainer = `#${newErrorContainer}`;
          }
        });
      document.dispatchEvent(new Event("rebind-validation"));
    })
    .on("cocoon:before-insert", (e, bl) => {
      if ($(bl).find(".nested-fields").length + 1 > maxGuests) {
        e.preventDefault();
      }
      let display = "table-row";
      if ($(bl).hasClass("extended")) {
        display = "block";
      }

      $(bl).css("display", display);
    })
    .on("cocoon:before-remove", () => {
      if ($(block).find(".nested-fields").length < maxGuests) {
        $(block).find(".add_guest_action").removeClass("hide");
      }
    })
    .on("cocoon:after-remove", () => {
      document.dispatchEvent(new Event("rebind-validation"));
    });
};

export const checkProposalRequestStatus = (elem) => {
  if (elem === null) {
    return;
  }

  const url = elem.dataset.pollingUrl;
  const delay = elem.dataset.pollingDelay;

  setTimeout(() => {
    $.ajax({
      dataType: "script",
      url,
      type: "GET",
      headers: {
        "X-CSRF-Token": getCSRFTokenValue(),
      },
    });
  }, delay);
};
