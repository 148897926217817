import $ from 'jquery';
import { initializeSemanticUI } from '../../plugins/SemanticUI';

const validateInviteTemplate = () => {
  if (document.querySelector('[data-invite-template]')) {
    const inviteRegex = /\[Complete My Profile Now\]\(\{\{invitation_link\}\}\)/i;
    const websiteRegex = /\s+https:\/\/app.groupize.com/i;

    let strBackup = $('#email_template_body').val();

    $('#email_template_body').on('keydown focusout', (ev) => {
      const query = ev.currentTarget.value;
      const inviteCondition = !inviteRegex.test(query);
      const websiteCondition = !websiteRegex.test(query);
      const conditions = (inviteCondition || websiteCondition);

      if (conditions) {
        ev.preventDefault();
        $('#email_template_body').val(strBackup);
      } else {
        strBackup = $('#email_template_body').val();
      }
    });
  }
};

const validateAssignExpertTemplate = () => {
  if (document.querySelector('[data-assign-expert-template]')) {
    const eventRegex = /\{\{event_ebids_link\}\}/i;

    let strBackup = $('#email_template_body').val();

    $('#email_template_body').on('keydown focusout', (ev) => {
      const query = ev.currentTarget.value;
      const eventCondition = !eventRegex.test(query);

      if (eventCondition) {
        ev.preventDefault();
        $('#email_template_body').val(strBackup);
      } else {
        strBackup = $('#email_template_body').val();
      }
    });
  }
};

export const validateTemplates = () => {
  validateAssignExpertTemplate();
  validateInviteTemplate();
};

const init = () => {
  if (document.querySelector('#manage-email-templates')) {
    initializeSemanticUI();
    $('.remove_attachment').on('click', (e) => {
      $(e.target).parents('li').remove();
    });
    validateTemplates();
  }
};

document.addEventListener('DOMContentLoaded', init);
document.addEventListener('emailtemplate:validate', validateTemplates);
