import { initializeSemanticUI } from "../plugins/SemanticUI";

const init = () => {
  if (document.querySelector("[data-password-change]")) {
    const passInput = document.getElementById("gr_password");
    const listMinLength = document.getElementById("min_length");
    const listHasUppercase = document.getElementById("has_uppercase");
    const listHasLowercase = document.getElementById("has_lowercase");
    const listHasNumber = document.getElementById("has_number");
    const listHasSymbol = document.getElementById("has_symbol");

    passInput.oninput = () => {
      const passValue = passInput.value;
      listMinLength.className = passValue.length >= 12 ? "success" : "";
      listHasNumber.className = passValue.match(/\d/g) ? "success" : "";
      listHasUppercase.className = passValue.match(/[A-Z]/g) ? "success" : "";
      listHasLowercase.className = passValue.match(/[a-z]/g) ? "success" : "";
      listHasSymbol.className = passValue.match(/.*[^a-zA-Z0-9]/g)
        ? "success"
        : "";
    };
    initializeSemanticUI();
  }
};
document.addEventListener("DOMContentLoaded", init);
