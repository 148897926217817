const initButtonDropdowns = () => {
  document.querySelectorAll('.bttn-dropdown').forEach((el) => {
    el.addEventListener('click', () => {
      el.classList.toggle('bttn-dropdown--open');
    });
  });
};

document.addEventListener('DOMContentLoaded', () => {
  initButtonDropdowns();
});
