import $ from 'jquery';

const initDocumentsEvents = () => {
  $('#documents')
    .on('cocoon:after-insert', () => {
      window.setUniqIds('document_description');
      document.dispatchEvent(new Event('quill:rebind'));
    })
    .on('cocoon:before-remove', (e, item) => {
      $(e.target).data('remove-timeout', 400);
      item.slideUp(300);
    });
};

const init = () => {
  if (document.querySelector('#documents')) {
    initDocumentsEvents();
  }
};
document.addEventListener('DOMContentLoaded', init);
document.addEventListener('documentmanagement:init', init);
