import $ from 'jquery';

const init = () => {
  if (!document.querySelector('[data-payment-management]')) {
    return;
  }

  const paymentLineAmount = document.getElementById('payment_line');
  const paymentFormAmount = document.getElementById('payment_amount');
  const paymentBalance = document.getElementById('payment_balance');
  const transactionPaymentsBalance = document.getElementById('transaction_payments_balance');
  const balanceClass = document.getElementById('balance_color');
  const convertedAmountInput = document.getElementById('payment_converted_transaction_amount_cents');
  const paymentCurrency = document.getElementById('payment_amount_currency');

  function updateBalance() {
    setTimeout(() => {
      paymentFormAmount.value = paymentLineAmount.value;
      const aux = parseFloat(transactionPaymentsBalance.innerHTML);
      const rate = $('.selected').data('exchange-rate') || 1;
      const convertedAmount = paymentLineAmount.value * rate;
      const balance = parseFloat(aux - parseFloat(convertedAmount || 0)).toFixed(2);
      convertedAmountInput.value = convertedAmount * 100;
      paymentBalance.innerHTML = balance;
      if (balance >= 0) {
        balanceClass.className = 'tw-text-green-500';
      } else {
        balanceClass.className = 'tw-text-red-500';
      }
    }, 200);
  }

  paymentLineAmount.addEventListener('input', updateBalance);
  paymentCurrency.addEventListener('change', updateBalance);
};
document.addEventListener('DOMContentLoaded', init);
